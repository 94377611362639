// *** Version: 1 ***

/* eslint max-len: 0, object-curly-newline: 0 */
const rawUpcyclingPricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    isSimpleOnly: true,
    categories: [
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 50, max: 60 } }, id: '1.mant-upc-1' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 25 }, id: '1.mant-upc-2' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 25 }, id: '1.mant-upc-3' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 95, max: 110 } }, id: '1.mant-upc-4' },
          { name: 'Création de col', prices: { SIMPLE: { min: 100, max: 140 } }, id: '1.mant-upc-5' },
        ],
      },
      {
        name: 'Haut global',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 90, max: 130 } }, liningPrices: { SIMPLE: 40 }, id: '1.mant-upc-6' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 15 }, id: '1.mant-upc-7' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 60 }, id: '1.mant-upc-8' },
          { name: 'Coudières (x2)', prices: { SIMPLE: 28 }, id: '1.mant-upc-9' },
          { name: 'Épaulettes (x2)', prices: { SIMPLE: 35 }, liningPrices: { SIMPLE: 20 }, id: '1.mant-upc-10' },
          { name: 'Pince (x1)', prices: { SIMPLE: 30 }, liningPrices: { SIMPLE: 10 }, id: '1.mant-upc-11' },
          { name: 'Poche', prices: { SIMPLE: 40 }, id: '1.mant-upc-12' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 3 }, id: '1.mant-upc-13' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 15 }, liningPrices: { SIMPLE: 4 }, id: '1.mant-upc-14' },
          { name: 'Passant', prices: { SIMPLE: 15 }, id: '1.mant-upc-15' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 20 }, id: '1.mant-upc-16' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 35 }, id: '1.mant-upc-17' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 12 }, id: '1.mant-upc-18' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 18 }, id: '1.mant-upc-19' },
        ],
      },
    ],
  },


  {
    name: 'Haut',
    slug: 'haut',
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', hasPriceRange: true, prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '1.haut-upc-1' },
          { name: 'Décolleté', hasPriceRange: true, prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '1.haut-upc-2' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', hasPriceRange: true, prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '1.haut-upc-3' },
          { name: 'Création de manches', hasPriceRange: true, prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.haut-upc-4' },
          { name: 'Structuration des épaules', hasPriceRange: true, prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.haut-upc-5' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', hasPriceRange: true, prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '1.haut-upc-6' },
          { name: 'Création de col', hasPriceRange: true, prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '1.haut-upc-7' },
        ],
      },
      {
        name: 'Haut global',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', hasPriceRange: true, prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 90, max: 130 } }, liningPrices: { SIMPLE: 25, COMPLEX: 40 }, id: '1.haut-upc-8' },
          { name: 'Rigidification : donner de la tenue', hasPriceRange: true, prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '1.haut-upc-9' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 12, COMPLEX: 17 }, id: '1.haut-upc-10' },
          { name: 'Zip invisible', prices: { SIMPLE: 18, COMPLEX: 27 }, id: '1.haut-upc-11' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 12, COMPLEX: 18 }, id: '1.haut-upc-12' },
          { name: 'Coudières (x2)', prices: { SIMPLE: 15, COMPLEX: 19 }, id: '1.haut-upc-13' },
          { name: 'Épaulettes (x2)', prices: { SIMPLE: 16, COMPLEX: 25 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, id: '1.haut-upc-14' },
          { name: 'Poignets (x2)', prices: { SIMPLE: 12, COMPLEX: 23 }, id: '1.haut-upc-15' },
          { name: 'Pince (x1)', prices: { SIMPLE: 25, COMPLEX: 30 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.haut-upc-16' },
          { name: 'Poche', prices: { SIMPLE: 12, COMPLEX: 15 }, id: '1.haut-upc-17' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 1, COMPLEX: 2 }, id: '1.haut-upc-18' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 7, COMPLEX: 10 }, id: '1.haut-upc-19' },
          { name: 'Passant', prices: { SIMPLE: 7, COMPLEX: 10 }, id: '1.haut-upc-20' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 10, COMPLEX: 10 }, id: '1.haut-upc-21' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 15, COMPLEX: 18 }, id: '1.haut-upc-22' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 8, COMPLEX: 8 }, id: '1.haut-upc-23' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 11, COMPLEX: 11 }, id: '1.haut-upc-24' },
        ],
      },
    ],
  },


  {
    name: 'Pantalon',
    slug: 'pant',
    categories: [
      {
        name: 'Structure globale',
        items: [
          { name: 'Cintrer de plus d’une taille', hasPriceRange: true, prices: { SIMPLE: { min: 60, max: 80 }, COMPLEX: { min: 80, max: 110 } }, liningPrices: { SIMPLE: 20, COMPLEX: 30 }, id: '1.pant-upc-1' },
          { name: 'Rigidification : donner de la tenue', hasPriceRange: true, prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '1.pant-upc-2' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 17, COMPLEX: 19 }, liningPrices: { SIMPLE: 5, COMPLEX: 7 }, id: '1.pant-upc-3' },
          { name: 'Zip invisible', prices: { SIMPLE: 25, COMPLEX: 27 }, liningPrices: { SIMPLE: 7, COMPLEX: 12 }, id: '1.pant-upc-4' },
          { name: 'Genouillères (x2)', prices: { SIMPLE: 17, COMPLEX: 20 }, id: '1.pant-upc-5' },
          { name: 'Pince (x1)', prices: { SIMPLE: 14, COMPLEX: 20 }, liningPrices: { SIMPLE: 3, COMPLEX: 4 }, id: '1.pant-upc-6' },
          { name: 'Poche', prices: { SIMPLE: 20, COMPLEX: 25 }, id: '1.pant-upc-7' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 3, COMPLEX: 3 }, id: '1.pant-upc-8' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { SIMPLE: 1, COMPLEX: 2 }, id: '1.pant-upc-9' },
          { name: 'Passant', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { COMPLEX: 2 }, id: '1.pant-upc-10' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 10, COMPLEX: 10 }, liningPrices: { COMPLEX: 2 }, id: '1.pant-upc-11' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 22, COMPLEX: 22 }, id: '1.pant-upc-12' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 8, COMPLEX: 8 }, id: '1.pant-upc-13' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 11, COMPLEX: 11 }, id: '1.pant-upc-14' },
        ],
      },
    ],
  },


  {
    name: 'Jupe',
    slug: 'jupe',
    categories: [
      {
        name: 'Jupe globale',
        items: [
          { name: 'Transformation > Pantalon', hasPriceRange: true, prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.jupe-upc-1' },
          { name: 'Cintrer d’une taille intégralement ou plus', hasPriceRange: true, prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 90, max: 130 } }, liningPrices: { SIMPLE: 25, COMPLEX: 40 }, id: '1.jupe-upc-2' },
          { name: 'Rigidification : donner de la tenue', hasPriceRange: true, prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '1.jupe-upc-3' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 22, COMPLEX: 35 }, liningPrices: { SIMPLE: 10, COMPLEX: 13 }, id: '1.jupe-upc-4' },
          { name: 'Zip invisible', prices: { SIMPLE: 29, COMPLEX: 43 }, liningPrices: { SIMPLE: 11, COMPLEX: 15 }, id: '1.jupe-upc-5' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 12, COMPLEX: 18 }, id: '1.jupe-upc-6' },
          { name: 'Fente', prices: { SIMPLE: 25, COMPLEX: 35 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.jupe-upc-7' },
          { name: 'Pince (x1)', prices: { SIMPLE: 25, COMPLEX: 30 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.jupe-upc-8' },
          { name: 'Poche', prices: { SIMPLE: 20, COMPLEX: 25 }, id: '1.jupe-upc-9' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 3, COMPLEX: 4 }, id: '1.jupe-upc-10' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { SIMPLE: 1, COMPLEX: 2 }, id: '1.jupe-upc-11' },
          { name: 'Passant', prices: { SIMPLE: 7, COMPLEX: 14 }, id: '1.jupe-upc-12' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 10, COMPLEX: 14 }, id: '1.jupe-upc-13' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 20, COMPLEX: 22 }, id: '1.jupe-upc-14' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 8, COMPLEX: 8 }, id: '1.jupe-upc-15' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 11, COMPLEX: 11 }, id: '1.jupe-upc-16' },
        ],
      },
    ],
  },


  {
    name: 'Robe',
    slug: 'robe',
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', hasPriceRange: true, prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '1.robe-upc-1' },
          { name: 'Décolleté', hasPriceRange: true, prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '1.robe-upc-2' },
          { name: 'Séparation haut / bas', hasPriceRange: true, prices: { SIMPLE: { min: 50, max: 60 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '1.robe-upc-3' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', hasPriceRange: true, prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '1.robe-upc-4' },
          { name: 'Création de manches', hasPriceRange: true, prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.robe-upc-5' },
          { name: 'Structuration des épaules', hasPriceRange: true, prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 25, COMPLEX: 25 }, id: '1.robe-upc-6' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', hasPriceRange: true, prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '1.robe-upc-7' },
          { name: 'Création de col', hasPriceRange: true, prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '1.robe-upc-8' },
        ],
      },
      {
        name: 'Bas de robe / jupe',
        items: [
          { name: 'Transfo > Pantalon', hasPriceRange: true, prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.robe-upc-9' },
          { ref: '1.robe-upc-3' },
        ],
      },
      {
        name: 'Robe globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', hasPriceRange: true, prices: { SIMPLE: { min: 90, max: 120 }, COMPLEX: { min: 130, max: 180 } }, liningPrices: { SIMPLE: 30, COMPLEX: 50 }, id: '1.robe-upc-11' },
          { name: 'Rigidification : donner de la tenue', hasPriceRange: true, prices: { SIMPLE: { min: 50, max: 70 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '1.robe-upc-12' },
          { ref: '1.robe-upc-3' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 22, COMPLEX: 35 }, liningPrices: { SIMPLE: 10, COMPLEX: 13 }, id: '1.robe-upc-14' },
          { name: 'Zip invisible', prices: { SIMPLE: 29, COMPLEX: 43 }, liningPrices: { SIMPLE: 11, COMPLEX: 15 }, id: '1.robe-upc-15' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 12, COMPLEX: 18 }, id: '1.robe-upc-16' },
          { name: 'Fente', prices: { SIMPLE: 25, COMPLEX: 35 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.robe-upc-17' },
          { name: 'Pince (x1)', prices: { SIMPLE: 25, COMPLEX: 30 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.robe-upc-18' },
          { name: 'Poche', prices: { SIMPLE: 20, COMPLEX: 25 }, id: '1.robe-upc-19' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 3, COMPLEX: 4 }, id: '1.robe-upc-20' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { SIMPLE: 1, COMPLEX: 2 }, id: '1.robe-upc-21' },
          { name: 'Passant', prices: { SIMPLE: 7, COMPLEX: 14 }, id: '1.robe-upc-22' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 10, COMPLEX: 14 }, id: '1.robe-upc-23' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 20, COMPLEX: 22 }, id: '1.robe-upc-24' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 8, COMPLEX: 8 }, id: '1.robe-upc-25' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 11, COMPLEX: 11 }, id: '1.robe-upc-26' },
        ],
      },
    ],
  },


  {
    name: 'Robe de mariée',
    slug: 'mari',
    isSimpleOnly: true,
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 50, max: 80 } }, liningPrices: { SIMPLE: 15 }, id: '1.mari-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 110, max: 140 } }, liningPrices: { SIMPLE: 20 }, id: '1.mari-upc-2' },
          { name: 'Séparation haut / bas', prices: { SIMPLE: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 25 }, id: '1.mari-upc-3' },
          { name: 'Cintrer d’une taille ou plus', prices: { SIMPLE: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 15 }, id: '1.mari-upc-4' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 80, max: 100 } }, id: '1.mari-upc-5' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 110, max: 140 } }, liningPrices: { SIMPLE: 30 }, id: '1.mari-upc-6' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 110, max: 150 } }, liningPrices: { SIMPLE: 25 }, id: '1.mari-upc-7' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 110, max: 150 } }, id: '1.mari-upc-8' },
          { name: 'Création de col', prices: { SIMPLE: { min: 140, max: 170 } }, id: '1.mari-upc-9' },
        ],
      },
      {
        name: 'Bas de robe / jupe',
        items: [
          { name: 'Transfo > Pantalon', prices: { SIMPLE: { min: 170, max: 220 } }, liningPrices: { SIMPLE: 25 }, id: '1.mari-upc-10' },
          { ref: '1.mari-upc-3' },
        ],
      },
      {
        name: 'Robe globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 200, max: 250 } }, liningPrices: { SIMPLE: 50 }, id: '1.mari-upc-12' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 90, max: 120 } }, liningPrices: { SIMPLE: 25 }, id: '1.mari-upc-13' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip invisible', prices: { SIMPLE: 70 }, liningPrices: { SIMPLE: 15 }, id: '1.mari-upc-14' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 45 }, id: '1.mari-upc-15' },
          { name: 'Fente', prices: { SIMPLE: 35 }, liningPrices: { SIMPLE: 10 }, id: '1.mari-upc-16' },
          { name: 'Pince (x1)', prices: { SIMPLE: 30 }, liningPrices: { SIMPLE: 30 }, id: '1.mari-upc-17' },
          { name: 'Poche', prices: { SIMPLE: 35 }, id: '1.mari-upc-18' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 6 }, id: '1.mari-upc-19' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 12 }, liningPrices: { SIMPLE: 2 }, id: '1.mari-upc-20' },
          { name: 'Passant', prices: { SIMPLE: 20 }, id: '1.mari-upc-21' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 25 }, id: '1.mari-upc-22' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 40 }, id: '1.mari-upc-23' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 12 }, id: '1.mari-upc-24' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 18 }, id: '1.mari-upc-25' },
        ],
      },
    ],
  },

  {
    name: 'Combinaison',
    slug: 'comb',
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '1.comb-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '1.comb-upc-2' },
          { name: 'Séparation haut / bas', prices: { SIMPLE: { min: 50, max: 60 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '1.comb-upc-3' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '1.comb-upc-4' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.comb-upc-5' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.comb-upc-6' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '1.comb-upc-7' },
          { name: 'Création de col', prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '1.comb-upc-8' },
        ],
      },
      {
        name: 'Bas de combinaison (jupe ou pantalon)',
        items: [
          { name: 'Transfo Jupe > Pantalon', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '1.comb-upc-9' },
          { ref: '1.comb-upc-3' },
        ],
      },
      {
        name: 'Combinaison globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 90, max: 120 }, COMPLEX: { min: 130, max: 180 } }, liningPrices: { SIMPLE: 30, COMPLEX: 50 }, id: '1.comb-upc-10' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 50, max: 70 }, COMPLEX: { min: 70, max: 90 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '1.comb-upc-11' },
          { ref: '1.comb-upc-3' },
        ],
      },
      {
        name: 'Éléments de customisation',
        items: [
          { name: 'Zip', prices: { SIMPLE: 17, COMPLEX: 19 }, liningPrices: { SIMPLE: 5, COMPLEX: 7 }, id: '1.comb-upc-12' },
          { name: 'Zip invisible', prices: { SIMPLE: 25, COMPLEX: 27 }, liningPrices: { SIMPLE: 7, COMPLEX: 12 }, id: '1.comb-upc-13' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 12, COMPLEX: 18 }, id: '1.comb-upc-14' },
          { name: 'Fente', prices: { SIMPLE: 25, COMPLEX: 35 }, liningPrices: { SIMPLE: 5, COMPLEX: 10 }, id: '1.comb-upc-15' },
          { name: 'Pince (x1)', prices: { SIMPLE: 14, COMPLEX: 20 }, liningPrices: { SIMPLE: 3, COMPLEX: 4 }, id: '1.comb-upc-16' },
          { name: 'Poche', prices: { SIMPLE: 20, COMPLEX: 25 }, id: '1.comb-upc-17' },
          { name: 'Bouton (x1)', prices: { SIMPLE: 3, COMPLEX: 3 }, id: '1.comb-upc-18' },
          { name: 'Boutonnière (x1)', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { SIMPLE: 1, COMPLEX: 2 }, id: '1.comb-upc-19' },
          { name: 'Passant', prices: { SIMPLE: 7, COMPLEX: 10 }, liningPrices: { COMPLEX: 2 }, id: '1.comb-upc-20' },
          { name: 'Couture d’appoint complexe', prices: { SIMPLE: 10, COMPLEX: 10.5 }, liningPrices: { COMPLEX: 3 }, id: '1.comb-upc-21' },
          { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 22, COMPLEX: 22 }, liningPrices: { COMPLEX: 4 }, id: '1.comb-upc-22' },
          { name: 'Perlage (x10cm de long - grosses perles)', prices: { SIMPLE: 8, COMPLEX: 8 }, id: '1.comb-upc-23' },
          { name: 'Perlage (x10cm de long - petites perles)', prices: { SIMPLE: 11, COMPLEX: 11 }, id: '1.comb-upc-24' },
        ],
      },
    ],
  },
];

const upcyclingPricing = rawUpcyclingPricing.map(cloth => ({
  ...cloth,
  categories: cloth.categories.map(category => ({
    ...category,
    items: category.items.map(item => ({
      ...item,
      hasPriceRange: !!item.prices?.SIMPLE?.min || !!item.prices?.COMPLEX?.min,
    })),
  })),
}));

const upcyclingPricingInverse = {};
upcyclingPricing.forEach(
  cloth => cloth.categories.forEach(
    category => category.items
      .filter(({ ref }) => !ref)
      .forEach(
        (item) => {
          if (upcyclingPricingInverse[item.id]) {
            throw new Error(`Inverse pricing, duplicate key : ${item.id}`);
          }
          upcyclingPricingInverse[item.id] = {
            ...item,
            cloth: cloth.name,
            category: category.name,
          };
        },
      ),
  ),
);
export { upcyclingPricingInverse };

export default upcyclingPricing;
