// *** Version: 8 ***

/* eslint max-len: 0, object-curly-newline: 0 */
const rawPricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    types: [],
    hasMostCommonCategory: true,
    showInPlugin: true,
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', price: 47, liningUnitPrice: 15, showInPlugin: true, id: '8.mant-cin-1' },
          { name: 'Manches (x2)', price: 59, liningUnitPrice: 0, showInPlugin: true, id: '8.mant-cin-2' },
          { name: 'Taille', price: 37, liningUnitPrice: 13, showInPlugin: true, id: '8.mant-cin-3' },
          { name: 'Dos', price: 20, liningUnitPrice: 7, showInPlugin: true, id: '8.mant-cin-4', mostCommonCategoryPosition: 2, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', price: 52, liningUnitPrice: 18, showInPlugin: true, id: '8.mant-ela-1' },
          { name: 'Manches (x2)', price: 64, liningUnitPrice: 0, showInPlugin: true, id: '8.mant-ela-2' },
          { name: 'Taille', price: 46, liningUnitPrice: 15, showInPlugin: true, id: '8.mant-ela-3' },
          { name: 'Dos', price: 25, liningUnitPrice: 8, showInPlugin: true, id: '8.mant-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', price: 29, liningUnitPrice: 12, showInPlugin: true, id: '8.mant-rac-1', mostCommonCategoryPosition: 1, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manche (x2 - par le bas)', price: 25, liningUnitPrice: 5, id: '8.mant-rac-2' },
          { name: 'Manche (x2 - par l’épaule)', price: 65, liningUnitPrice: 15, showInPlugin: true, id: '8.mant-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', price: 35, liningUnitPrice: 12, showInPlugin: true, id: '8.mant-ral-1' },
          { name: 'Manche (x2 - par le bas)', price: 27, liningUnitPrice: 25, id: '8.mant-ral-2' },
          { name: 'Manche (x2 - par l’épaule)', price: 85, liningUnitPrice: 25, showInPlugin: true, id: '8.mant-ral-3' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 10, id: '8.mant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 15, id: '8.mant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 45, id: '8.mant-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', price: 80, id: '8.mant-bro-11', multiple: true },
          { name: 'Dessin creux forme simple < 5cm', price: 10, id: '8.mant-bro-4', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, id: '8.mant-bro-5', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, id: '8.mant-bro-6', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, id: '8.mant-bro-12', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, id: '8.mant-bro-13', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, id: '8.mant-bro-14', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, id: '8.mant-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, id: '8.mant-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, id: '8.mant-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, id: '8.mant-bro-10', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : changement intégral', price: 68, id: '8.mant-div-1' },
          { name: 'Doublure : changement partiel', price: 45, id: '8.mant-div-2', mostCommonCategoryPosition: 3 },
          { name: 'Coudières (x2) : ajout / remplacement', price: 20, id: '8.mant-div-3' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', price: 21, liningUnitPrice: 2, id: '8.mant-div-4' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 22, liningUnitPrice: 3, id: '8.mant-div-5', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', price: 26, liningUnitPrice: 4, id: '8.mant-div-25', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 23, liningUnitPrice: 4, id: '8.mant-div-26', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', price: 29, liningUnitPrice: 7, id: '8.mant-div-27', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 12, id: '8.mant-div-7', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', price: 15, id: '8.mant-div-20', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', price: 19, id: '8.mant-div-28', multiple: true },
          { name: 'Poche complexe (x1) : ajout', price: 22, id: '8.mant-div-8', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 4, liningUnitPrice: 1, id: '8.mant-div-18', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', price: 3, id: '8.mant-div-12', multiple: true, mostCommonCategoryLabel: 'Bouton à l’unité' },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 4, id: '8.mant-div-22', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 1, id: '8.mant-div-21', multiple: true },
          { name: 'Trou : remaillage (< 10 trous)', price: 4, id: '8.mant-div-19', multiple: true },
          { name: 'Trou : remaillage (> 10 trous)', price: 6, id: '8.mant-div-32', multiple: true },
          { name: 'Déchirure : réparation', price: 12, id: '8.mant-div-13', multiple: true, mostCommonCategoryPosition: 5 },
          { name: 'Passant (x1) : ajout / remplacement', price: 8, id: '8.mant-div-14', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 6, id: '8.mant-div-15', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 12, id: '8.mant-div-16', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 17, id: '8.mant-div-17', multiple: true },
          { name: 'Perlage (5 à 10 perles)', price: 17, id: '8.mant-div-23', multiple: true },
          { name: 'Pince (x1) : ajout', price: 12, liningUnitPrice: 1, id: '8.mant-div-29', multiple: true },
          { name: 'Fente : ajout / retrait', price: 18, liningUnitPrice: 6, id: '8.mant-div-30', multiple: true },
          { name: 'Élastique : ajout / remplacement', price: 17, liningUnitPrice: 1, id: '8.mant-div-33', multiple: true },

        ],
      },
    ],
  },


  {
    name: 'Haut',
    slug: 'haut',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'habillé' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 14, showInPlugin: true, id: '8.haut-cin-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 14, showInPlugin: true, id: '8.haut-cin-2' },
          { name: 'Taille', type: 'SIMPLE', price: 14, showInPlugin: true, id: '8.haut-cin-3' },
          { name: 'Dos', type: 'SIMPLE', price: 9, showInPlugin: true, id: '8.haut-cin-4', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '8.haut-cin-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '8.haut-cin-6' },
          { name: 'Taille', type: 'COMPLEX', price: 25, showInPlugin: true, id: '8.haut-cin-7' },
          { name: 'Dos', type: 'COMPLEX', price: 18, showInPlugin: true, id: '8.haut-cin-8', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.haut-ela-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.haut-ela-2' },
          { name: 'Taille', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.haut-ela-3' },
          { name: 'Dos', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.haut-ela-4' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 28, showInPlugin: true, id: '8.haut-ela-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '8.haut-ela-6' },
          { name: 'Taille', type: 'COMPLEX', price: 32, showInPlugin: true, id: '8.haut-ela-7' },
          { name: 'Dos', type: 'COMPLEX', price: 20, showInPlugin: true, id: '8.haut-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 8, showInPlugin: true, id: '8.haut-rac-1', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.haut-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 28, id: '8.haut-rac-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 21, showInPlugin: true, id: '8.haut-rac-4', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 23, showInPlugin: true, id: '8.haut-rac-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 65, id: '8.haut-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.haut-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.haut-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 30, id: '8.haut-ral-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 24, showInPlugin: true, id: '8.haut-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 27, showInPlugin: true, id: '8.haut-ral-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 75, id: '8.haut-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.haut-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.haut-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.haut-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.haut-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.haut-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.haut-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.haut-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.haut-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.haut-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.haut-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.haut-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.haut-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.haut-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.haut-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.haut-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.haut-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.haut-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.haut-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.haut-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.haut-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.haut-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.haut-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.haut-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.haut-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.haut-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.haut-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.haut-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.haut-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '8.haut-div-2' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 18, liningUnitPrice: 2, id: '8.haut-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '8.haut-div-4' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.haut-div-5', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.haut-div-42', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.haut-div-6', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.haut-div-44', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.haut-div-49', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.haut-div-34', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.haut-div-50', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.haut-div-7', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.haut-div-32', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.haut-div-10', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.haut-div-37', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.haut-div-36', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.haut-div-53', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.haut-div-11', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.haut-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.haut-div-13', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.haut-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.haut-div-15', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.haut-div-56' },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.haut-div-39', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.haut-div-58', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.haut-div-61', multiple: true },

          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '8.haut-div-17' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 21, liningPriceUnit: 2, id: '8.haut-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 41, id: '8.haut-div-19' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.haut-div-20', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.haut-div-46', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.haut-div-21', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.haut-div-48', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.haut-div-51', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.haut-div-35', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.haut-div-52', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.haut-div-22', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.haut-div-33', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.haut-div-25', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.haut-div-38', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.haut-div-60', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.haut-div-31', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.haut-div-26', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.haut-div-27', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.haut-div-28', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.haut-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.haut-div-30', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.haut-div-57' },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.haut-div-40', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.haut-div-59', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.haut-div-62', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Pantalon',
    slug: 'pant',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 32, showInPlugin: true, id: '8.pant-cin-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 42, showInPlugin: true, id: '8.pant-cin-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 22, showInPlugin: true, id: '8.pant-cin-3', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.pant-cin-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 42, liningUnitPrice: 11, showInPlugin: true, id: '8.pant-cin-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 45, liningUnitPrice: 9, showInPlugin: true, id: '8.pant-cin-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 24, liningUnitPrice: 8, showInPlugin: true, id: '8.pant-cin-7', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 22, liningUnitPrice: 9, showInPlugin: true, id: '8.pant-cin-8' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 38, showInPlugin: true, id: '8.pant-ela-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 47, showInPlugin: true, id: '8.pant-ela-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 27, showInPlugin: true, id: '8.pant-ela-3' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.pant-ela-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 47, liningUnitPrice: 12, showInPlugin: true, id: '8.pant-ela-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 50, liningUnitPrice: 10, showInPlugin: true, id: '8.pant-ela-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 27, liningUnitPrice: 9, showInPlugin: true, id: '8.pant-ela-7' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 27, liningUnitPrice: 10, showInPlugin: true, id: '8.pant-ela-8' },
        ],
      },
      {
        name: 'Ourlets',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple (x2)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.pant-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.pant-rac-2' },

          { name: 'Ourlet simple (x2)', type: 'COMPLEX', price: 17, liningUnitPrice: 7, showInPlugin: true, id: '8.pant-rac-3', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '8.pant-rac-4' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.pant-ral-1' },

          { name: 'Jambes (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '8.pant-ral-2' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'COMPLEX', price: 17, liningUnitPrice: 7, showInPlugin: true, id: '8.pant-ral-3' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.pant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.pant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.pant-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.pant-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.pant-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.pant-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.pant-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.pant-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.pant-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.pant-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.pant-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.pant-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.pant-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.pant-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.pant-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.pant-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.pant-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.pant-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.pant-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.pant-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.pant-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.pant-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.pant-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.pant-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.pant-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.pant-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.pant-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.pant-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.pant-div-1', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.pant-div-34', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.pant-div-2', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.pant-div-36', multiple: true },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '8.pant-div-3' },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.pant-div-4', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.pant-div-25', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.pant-div-42', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.pant-div-5', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.pant-div-50', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.pant-div-6', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.pant-div-29', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.pant-div-27', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.pant-div-7', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.pant-div-8', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.pant-div-9', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.pant-div-10', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.pant-div-11', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.pant-div-12', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.pant-div-46' },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.pant-div-31', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.pant-div-48', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.pant-div-52', multiple: true },

          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.pant-div-13', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.pant-div-38', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.pant-div-14', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.pant-div-40', multiple: true },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '8.pant-div-45' },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.pant-div-15', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.pant-div-26', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.pant-div-44', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.pant-div-16', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 57, liningUnitPrice: 15, id: '8.pant-div-17', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.pant-div-51', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.pant-div-18', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.pant-div-30', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.pant-div-28', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.pant-div-19', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.pant-div-20', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.pant-div-21', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.pant-div-22', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.pant-div-23', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.pant-div-24', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.pant-div-47' },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.pant-div-32', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.pant-div-49', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.pant-div-53', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Jupe',
    slug: 'jupe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'simple' },
      { slug: 'COMPLEX', name: 'habillée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-cin-2' },
          { name: 'Taille / ceinture', type: 'SIMPLE', price: 27, liningUnitPrice: 3, showInPlugin: true, id: '8.jupe-cin-1', mostCommonCategoryPosition: 5 },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-cin-4' },
          { name: 'Taille / ceinture', type: 'COMPLEX', price: 33, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-cin-3', mostCommonCategoryPosition: 5 },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 22, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-ela-2' },
          { name: 'Taille / ceinture', type: 'SIMPLE', price: 29, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-ela-1' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 25, liningUnitPrice: 6, showInPlugin: true, id: '8.jupe-ela-4' },
          { name: 'Taille / ceinture', type: 'COMPLEX', price: 35, liningUnitPrice: 6, showInPlugin: true, id: '8.jupe-ela-3' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 12, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'SIMPLE', price: 18, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-rac-2' },
          { name: 'Ourlet à volants', type: 'SIMPLE', price: 22, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-rac-3' },

          { name: 'Ourlet simple', type: 'COMPLEX', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-rac-4', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-rac-5' },
          { name: 'Ourlet à volants', type: 'COMPLEX', price: 28, liningUnitPrice: 6, showInPlugin: true, id: '8.jupe-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 15, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-ral-1' },
          { name: 'Longueur ample', type: 'SIMPLE', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-ral-2' },
          { name: 'Longueur à volants', type: 'SIMPLE', price: 22, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-ral-5' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.jupe-ral-3' },
          { name: 'Longueur ample', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-ral-4' },
          { name: 'Longueur à volants', type: 'COMPLEX', price: 30, liningUnitPrice: 5, showInPlugin: true, id: '8.jupe-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.jupe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.jupe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.jupe-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.jupe-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.jupe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.jupe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.jupe-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.jupe-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.jupe-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.jupe-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.jupe-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.jupe-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.jupe-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.jupe-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.jupe-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.jupe-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.jupe-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.jupe-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.jupe-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.jupe-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.jupe-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.jupe-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.jupe-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.jupe-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.jupe-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.jupe-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.jupe-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.jupe-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.jupe-div-1' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.jupe-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.jupe-div-40', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.jupe-div-3', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.jupe-div-42', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.jupe-div-4', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.jupe-div-5', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.jupe-div-6', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.jupe-div-31', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.jupe-div-47', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.jupe-div-7', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.jupe-div-29', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.jupe-div-9', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.jupe-div-35', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.jupe-div-33', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.jupe-div-10', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.jupe-div-11', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.jupe-div-12', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.jupe-div-13', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.jupe-div-14', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.jupe-div-37', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.jupe-div-49', multiple: true },

          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.jupe-div-15' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.jupe-div-16', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.jupe-div-44', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.jupe-div-17', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.jupe-div-46', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.jupe-div-18', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.jupe-div-19', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.jupe-div-20', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.jupe-div-32', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.jupe-div-48', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.jupe-div-21', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.jupe-div-30', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.jupe-div-23', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.jupe-div-36', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.jupe-div-34', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.jupe-div-24', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.jupe-div-25', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.jupe-div-26', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.jupe-div-27', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.jupe-div-28', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.jupe-div-38', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.jupe-div-50', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Robe',
    slug: 'robe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'petite robe classique' },
      { slug: 'COMPLEX', name: 'robe de soirée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 22, liningUnitPrice: 3, showInPlugin: true, id: '8.robe-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.robe-cin-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 32, showInPlugin: true, id: '8.robe-cin-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 25, liningUnitPrice: 3, showInPlugin: true, id: '8.robe-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', type: 'SIMPLE', price: 21, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-cin-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-cin-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 24, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-cin-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 27, showInPlugin: true, id: '8.robe-cin-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 34, showInPlugin: true, id: '8.robe-cin-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 33, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-cin-11', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', type: 'COMPLEX', price: 24, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-cin-12' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 23, liningUnitPrice: 6, showInPlugin: true, id: '8.robe-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 25, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 25, showInPlugin: true, id: '8.robe-ela-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 35, showInPlugin: true, id: '8.robe-ela-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 27, liningUnitPrice: 3, showInPlugin: true, id: '8.robe-ela-5' },
          { name: 'Dos', type: 'SIMPLE', price: 26, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ela-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 26, liningUnitPrice: 6, showInPlugin: true, id: '8.robe-ela-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 30, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ela-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '8.robe-ela-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 38, showInPlugin: true, id: '8.robe-ela-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 35, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ela-11' },
          { name: 'Dos', type: 'COMPLEX', price: 27, liningUnitPrice: 6, showInPlugin: true, id: '8.robe-ela-12' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 12, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'SIMPLE', price: 18, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-rac-2' },
          { name: 'Ourlet à volants', type: 'SIMPLE', price: 22, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.robe-rac-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 22, showInPlugin: true, id: '8.robe-rac-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, showInPlugin: true, id: '8.robe-rac-6' },

          { name: 'Ourlet simple', type: 'COMPLEX', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-rac-7', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-rac-8' },
          { name: 'Ourlet à volants', type: 'COMPLEX', price: 28, liningUnitPrice: 6, showInPlugin: true, id: '8.robe-rac-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 16, showInPlugin: true, id: '8.robe-rac-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 26, showInPlugin: true, id: '8.robe-rac-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 18, showInPlugin: true, id: '8.robe-rac-12' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 15, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-ral-1' },
          { name: 'Longueur ample', type: 'SIMPLE', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-ral-2' },
          { name: 'Longueur à volants', type: 'SIMPLE', price: 22, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 14, showInPlugin: true, id: '8.robe-ral-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 25, showInPlugin: true, id: '8.robe-ral-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.robe-ral-6' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 20, liningUnitPrice: 4, showInPlugin: true, id: '8.robe-ral-7' },
          { name: 'Longueur ample', type: 'COMPLEX', price: 23, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ral-8' },
          { name: 'Longueur à volants', type: 'COMPLEX', price: 30, liningUnitPrice: 5, showInPlugin: true, id: '8.robe-ral-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 17, showInPlugin: true, id: '8.robe-ral-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 28, showInPlugin: true, id: '8.robe-ral-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, showInPlugin: true, id: '8.robe-ral-12' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.robe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.robe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.robe-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.robe-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.robe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.robe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.robe-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.robe-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.robe-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.robe-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.robe-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.robe-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.robe-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.robe-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.robe-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.robe-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.robe-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.robe-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.robe-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.robe-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.robe-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.robe-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.robe-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.robe-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.robe-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.robe-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.robe-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.robe-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.robe-div-1' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.robe-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.robe-div-52', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.robe-div-3', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.robe-div-40', multiple: true },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 18, liningUnitPrice: 2, id: '8.robe-div-47' },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.robe-div-5', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.robe-div-6', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.robe-div-7', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.robe-div-35', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.robe-div-45', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.robe-div-8', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.robe-div-33', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.robe-div-10', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.robe-div-39', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.robe-div-37', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.robe-div-11', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.robe-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.robe-div-13', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.robe-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.robe-div-15', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.robe-div-51', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.robe-div-54', multiple: true },

          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.robe-div-16' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.robe-div-17', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.robe-div-42', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.robe-div-18', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.robe-div-44', multiple: true },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 21, liningUnitPrice: 2, id: '8.robe-div-48' },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.robe-div-21', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.robe-div-22', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.robe-div-23', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.robe-div-36', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.robe-div-46', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.robe-div-24', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.robe-div-34', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.robe-div-26', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.robe-div-27', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.robe-div-38', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.robe-div-28', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.robe-div-29', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.robe-div-30', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.robe-div-31', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.robe-div-32', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.robe-div-53', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.robe-div-55', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Pull & gilet',
    slug: 'pull',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'laine & cachemire' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 16, showInPlugin: true, id: '8.pull-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 18, showInPlugin: true, id: '8.pull-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 21, showInPlugin: true, id: '8.pull-cin-3' },
          { name: 'Taille', type: 'SIMPLE', price: 18, showInPlugin: true, id: '8.pull-cin-4' },
          { name: 'Dos', type: 'SIMPLE', price: 11, showInPlugin: true, id: '8.pull-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 40, showInPlugin: true, id: '8.pull-cin-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 45, showInPlugin: true, id: '8.pull-cin-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 52, showInPlugin: true, id: '8.pull-cin-8' },
          { name: 'Taille', type: 'COMPLEX', price: 45, showInPlugin: true, id: '8.pull-cin-9' },
          { name: 'Dos', type: 'COMPLEX', price: 27, showInPlugin: true, id: '8.pull-cin-10', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 17, showInPlugin: true, id: '8.pull-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 19, showInPlugin: true, id: '8.pull-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 24, showInPlugin: true, id: '8.pull-ela-3' },
          { name: 'Taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.pull-ela-4' },
          { name: 'Dos', type: 'SIMPLE', price: 13, showInPlugin: true, id: '8.pull-ela-5' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 42, showInPlugin: true, id: '8.pull-ela-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 47, showInPlugin: true, id: '8.pull-ela-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 60, showInPlugin: true, id: '8.pull-ela-8' },
          { name: 'Taille', type: 'COMPLEX', price: 50, showInPlugin: true, id: '8.pull-ela-9' },
          { name: 'Dos', type: 'COMPLEX', price: 32, showInPlugin: true, id: '8.pull-ela-10' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 14, showInPlugin: true, id: '8.pull-rac-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.pull-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 37, showInPlugin: true, id: '8.pull-rac-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 35, showInPlugin: true, id: '8.pull-rac-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, showInPlugin: true, id: '8.pull-rac-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 92, showInPlugin: true, id: '8.pull-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 17, showInPlugin: true, id: '8.pull-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.pull-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 37, showInPlugin: true, id: '8.pull-ral-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 42, showInPlugin: true, id: '8.pull-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, showInPlugin: true, id: '8.pull-ral-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 92, showInPlugin: true, id: '8.pull-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.pull-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.pull-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.pull-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.pull-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.pull-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.pull-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.pull-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.pull-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.pull-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.pull-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.pull-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.pull-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.pull-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.pull-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.pull-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.pull-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.pull-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.pull-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.pull-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.pull-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.pull-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.pull-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.pull-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.pull-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.pull-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.pull-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.pull-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.pull-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '8.pull-div-2', mostCommonCategoryPosition: 2 },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 18, liningPriceUnit: 2, id: '8.pull-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '8.pull-div-4' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.pull-div-5', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.pull-div-46', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.pull-div-6', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.pull-div-48', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.pull-div-53', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.pull-div-37', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.pull-div-54', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.pull-div-7', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.pull-div-31', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.pull-div-10', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.pull-div-41', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.pull-div-39', multiple: true },
          { name: 'Trou : remaillage (< 10 trous)', type: 'SIMPLE', price: 4, id: '8.pull-div-33', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Trou : remaillage (> 10 trous)', type: 'SIMPLE', price: 6, id: '8.pull-div-35', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.pull-div-57', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.pull-div-11', multiple: true },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.pull-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.pull-div-13', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.pull-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.pull-div-15', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.pull-div-59' },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.pull-div-43', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.pull-div-61', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.pull-div-63', multiple: true },

          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '8.pull-div-17', mostCommonCategoryPosition: 2 },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 21, liningPriceUnit: 2, id: '8.pull-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 41, id: '8.pull-div-19' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.pull-div-20', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.pull-div-50', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.pull-div-21', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.pull-div-52', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.pull-div-55', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.pull-div-38', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.pull-div-56', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.pull-div-22', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.pull-div-32', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.pull-div-25', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.pull-div-42', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.pull-div-40', multiple: true },
          { name: 'Trou : remaillage (< 10 trous)', type: 'COMPLEX', price: 4, id: '8.pull-div-34', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Trou : remaillage (> 10 trous)', type: 'COMPLEX', price: 6, id: '8.pull-div-36', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.pull-div-58', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.pull-div-26', multiple: true },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.pull-div-27', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.pull-div-28', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.pull-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.pull-div-30', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.pull-div-60' },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.pull-div-44', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.pull-div-62', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.pull-div-64', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Lingerie & maillot',
    slug: 'ling',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'lingerie fine' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 9, showInPlugin: true, id: '8.ling-cin-1' },
          { name: 'Bonnets (x2)', type: 'SIMPLE', price: 12, liningUnitPrice: 3, showInPlugin: true, id: '8.ling-cin-2' },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.ling-cin-4' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 22, showInPlugin: true, id: '8.ling-cin-5' },
          { name: 'Bonnets (x2)', type: 'COMPLEX', price: 30, liningUnitPrice: 3, showInPlugin: true, id: '8.ling-cin-6' },
          { name: 'Tour de taille', type: 'COMPLEX', price: 30, showInPlugin: true, id: '8.ling-cin-8' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.ling-ela-1' },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.ling-ela-2' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 25, showInPlugin: true, id: '8.ling-ela-3' },
          { name: 'Tour de taille', type: 'COMPLEX', price: 40, showInPlugin: true, id: '8.ling-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, showInPlugin: true, id: '8.ling-rac-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 20, liningUnitPrice: 2, showInPlugin: true, id: '8.ling-rac-2' },
          { name: 'Ourlet', type: 'SIMPLE', price: 12, liningUnitPrice: 2, showInPlugin: true, id: '8.ling-rac-3', mostCommonCategoryPosition: 5 },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, showInPlugin: true, id: '8.ling-rac-4' },
          { name: 'Forme culotte / caleçon', type: 'COMPLEX', price: 30, liningUnitPrice: 2, showInPlugin: true, id: '8.ling-rac-5' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 9, showInPlugin: true, id: '8.ling-ral-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 22, liningUnitPrice: 2, showInPlugin: true, id: '8.ling-ral-2' },
          { name: 'Longueur', type: 'SIMPLE', price: 14, liningUnitPrice: 3, showInPlugin: true, id: '8.ling-ral-3' },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 22, showInPlugin: true, id: '8.ling-ral-4' },
          { name: 'Forme culotte / caleçon', type: 'COMPLEX', price: 35, liningUnitPrice: 2, showInPlugin: true, id: '8.ling-ral-5' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.ling-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.ling-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.ling-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.ling-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.ling-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.ling-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.ling-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.ling-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.ling-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.ling-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.ling-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.ling-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.ling-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.ling-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.ling-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.ling-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.ling-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.ling-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.ling-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.ling-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.ling-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.ling-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.ling-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.ling-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.ling-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.ling-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.ling-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.ling-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.ling-div-1', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.ling-div-36', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.ling-div-2', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.ling-div-38', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.ling-div-27', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.ling-div-6', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.ling-div-31', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.ling-div-29', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.ling-div-7', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.ling-div-43', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.ling-div-8', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Attache dos : remplacement', type: 'SIMPLE', price: 10, id: '8.ling-div-9', multiple: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'SIMPLE', price: 12, liningUnitPrice: 8, id: '8.ling-div-11', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.ling-div-45', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.ling-div-12', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.ling-div-13', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.ling-div-35', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.ling-div-55' },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.ling-div-33', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.ling-div-47', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.ling-div-48', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.ling-div-49', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.ling-div-50', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.ling-div-57', multiple: true },

          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.ling-div-14', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.ling-div-40', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.ling-div-15', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.ling-div-42', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.ling-div-28', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.ling-div-19', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.ling-div-32', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.ling-div-30', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.ling-div-20', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.ling-div-44', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.ling-div-21', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Attache dos : remplacement', type: 'COMPLEX', price: 15, id: '8.ling-div-22', multiple: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'COMPLEX', price: 17, liningUnitPrice: 17, id: '8.ling-div-24', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.ling-div-46', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.ling-div-25', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.ling-div-26', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.ling-div-60', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.ling-div-56' },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.ling-div-34', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.ling-div-51', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.ling-div-52', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.ling-div-53', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.ling-div-54', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.ling-div-58', multiple: true },
        ],
      },
    ],
  },

  {
    name: 'Combinaison',
    slug: 'comb',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 14.5, showInPlugin: true, id: '8.comb-cin-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 14.5, showInPlugin: true, id: '8.comb-cin-2' },
          { name: 'Taille', type: 'SIMPLE', price: 14.5, showInPlugin: true, id: '8.comb-cin-3' },
          { name: 'Dos', type: 'SIMPLE', price: 9, showInPlugin: true, id: '8.comb-cin-4' },
          { name: 'Ceinture', type: 'SIMPLE', price: 32, showInPlugin: true, id: '8.comb-cin-5' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 42, showInPlugin: true, id: '8.comb-cin-6' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 22, showInPlugin: true, id: '8.comb-cin-7', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-cin-8' },

          { name: 'Épaules (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '8.comb-cin-9' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '8.comb-cin-10' },
          { name: 'Taille', type: 'COMPLEX', price: 25, showInPlugin: true, id: '8.comb-cin-11' },
          { name: 'Dos', type: 'COMPLEX', price: 18, showInPlugin: true, id: '8.comb-cin-12' },
          { name: 'Ceinture', type: 'COMPLEX', price: 42, showInPlugin: true, liningUnitPrice: 11, id: '8.comb-cin-13' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 45, showInPlugin: true, liningUnitPrice: 9, id: '8.comb-cin-14' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 24, showInPlugin: true, liningUnitPrice: 8, id: '8.comb-cin-15', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 22, showInPlugin: true, liningUnitPrice: 9, id: '8.comb-cin-16' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-ela-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-ela-2' },
          { name: 'Taille', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-ela-3' },
          { name: 'Dos', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.comb-ela-4' },
          { name: 'Ceinture', type: 'SIMPLE', price: 38, showInPlugin: true, id: '8.comb-ela-5' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 47, showInPlugin: true, id: '8.comb-ela-6' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 27, showInPlugin: true, id: '8.comb-ela-7' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 20, showInPlugin: true, id: '8.comb-ela-8' },

          { name: 'Épaules (x2)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '8.comb-ela-9' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '8.comb-ela-10' },
          { name: 'Taille', type: 'COMPLEX', price: 28, showInPlugin: true, id: '8.comb-ela-11' },
          { name: 'Dos', type: 'COMPLEX', price: 20, showInPlugin: true, id: '8.comb-ela-12' },
          { name: 'Ceinture', type: 'COMPLEX', price: 47, liningUnitPrice: 12, showInPlugin: true, id: '8.comb-ela-13' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 50, liningUnitPrice: 10, showInPlugin: true, id: '8.comb-ela-14' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 27, liningUnitPrice: 9, showInPlugin: true, id: '8.comb-ela-15' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 27, liningUnitPrice: 10, showInPlugin: true, id: '8.comb-ela-16' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 10, showInPlugin: true, id: '8.comb-rac-1' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 28, showInPlugin: true, id: '8.comb-rac-2' },
          { name: 'Ourlet simple (x2)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.comb-rac-3', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-rac-4' },

          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 23, showInPlugin: true, id: '8.comb-rac-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 65, showInPlugin: true, id: '8.comb-rac-6' },
          { name: 'Ourlet simple (x2)', type: 'COMPLEX', price: 17, liningUnitPrice: 7, showInPlugin: true, id: '8.comb-rac-7', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '8.comb-rac-8' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '8.comb-ral-1' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 30, showInPlugin: true, id: '8.comb-ral-2' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '8.comb-ral-3' },

          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 27, showInPlugin: true, id: '8.comb-ral-4' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 75, showInPlugin: true, id: '8.comb-ral-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '8.comb-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.comb-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.comb-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.comb-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.comb-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.comb-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.comb-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.comb-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.comb-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.comb-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.comb-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.comb-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.comb-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.comb-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.comb-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.comb-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.comb-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.comb-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.comb-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.comb-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.comb-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.comb-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.comb-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.comb-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.comb-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.comb-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.comb-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.comb-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.comb-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '8.comb-div-2' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 18, liningPriceUnit: 2, id: '8.comb-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '8.comb-div-4' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.comb-div-6', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.comb-div-45', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.comb-div-7', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.comb-div-47', multiple: true },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '8.comb-div-8' },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 10, id: '8.comb-div-9', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE', price: 10, id: '8.comb-div-10', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'SIMPLE', price: 15, id: '8.comb-div-53', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE', price: 20, id: '8.comb-div-11', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.comb-div-12', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.comb-div-13', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.comb-div-40', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.comb-div-38', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.comb-div-14', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.comb-div-15', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.comb-div-16', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.comb-div-17', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.comb-div-18', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.comb-div-19', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '8.comb-div-58' },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.comb-div-42', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.comb-div-60', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.comb-div-62', multiple: true },

          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '8.comb-div-21' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 21, liningPriceUnit: 2, id: '8.comb-div-22' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 41, id: '8.comb-div-23' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.comb-div-25', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.comb-div-49', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.comb-div-26', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.comb-div-51', multiple: true },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'COMPLEX', price: 20, id: '8.comb-div-56' },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.comb-div-27', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.comb-div-28', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.comb-div-55', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.comb-div-29', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.comb-div-30', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.comb-div-31', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.comb-comb-41', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.comb-div-39', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.comb-div-32', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.comb-div-33', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.comb-div-34', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.comb-div-35', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.comb-div-36', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.comb-div-37', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 40, id: '8.comb-div-59' },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.comb-div-43', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.comb-div-61', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.comb-div-63', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Décoration',
    slug: 'deco',
    hasMostCommonCategory: false,
    types: [],
    categories: [
      {
        name: 'Rideaux',
        items: [
          { name: 'Ourlet - largeur <199cm', price: 20, liningUnitPrice: 10, id: '8.deco-rid-1', multiple: true },
          { name: 'Ourlet - largeur >200cm', price: 30, liningUnitPrice: 10, id: '8.deco-rid-2', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 4, id: '8.deco-rid-3', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 10, id: '8.deco-rid-4', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 15, id: '8.deco-rid-18', multiple: true },
          { name: 'Déchirure : réparation', price: 10, id: '8.deco-rid-5', multiple: true },
          { name: 'Passant (x1) : ajout / remplacement', price: 4, id: '8.deco-rid-11', multiple: true },
          { name: 'Supplément fil de plomb', price: 10, id: '8.deco-rid-6', multiple: true },
          { name: 'Assemblage côté - hauteur <199cm', price: 30, liningUnitPrice: 10, id: '8.deco-rid-7', multiple: true },
          { name: 'Assemblage côté - hauteur >200cm', price: 40, liningUnitPrice: 10, id: '8.deco-rid-8', multiple: true },
          { name: 'Ajout de fronces - largeur <199cm', price: 30, id: '8.deco-rid-9', multiple: true },
          { name: 'Ajout de fronces - largeur >200cm', price: 37, id: '8.deco-rid-10', multiple: true },
          { name: 'Dépose/pose d’un pan de rideau', price: 7, id: '8.deco-rid-30', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 3, liningUnitPrice: 1, id: '8.deco-rid-12', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', price: 2, id: '8.deco-rid-13', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 3, id: '8.deco-rid-14', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 1, id: '8.deco-rid-15', multiple: true },
          { name: 'Perlage (5 à 10 perles)', price: 15, id: '8.deco-rid-16', multiple: true },
          { name: 'Pince (x1) : ajout', price: 8, liningUnitPrice: 1, id: '8.deco-rid-17', multiple: true },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 15, liningUnitPrice: 3, id: '8.deco-rid-19', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', price: 20, liningUnitPrice: 3, id: '8.deco-rid-20', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 17, liningUnitPrice: 3, id: '8.deco-rid-21', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', price: 24, liningUnitPrice: 5, id: '8.deco-rid-22', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 10, id: '8.deco-rid-23', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', price: 10, id: '8.deco-rid-24', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', price: 15, id: '8.deco-rid-25', multiple: true },
          { name: 'Poche complexe (x1) : ajout', price: 20, id: '8.deco-rid-26', multiple: true },
          { name: 'Doublure : ajout / remplacement', price: 35, id: '8.deco-rid-27' },
          { name: 'Fente : ajout / retrait', price: 12, liningUnitPrice: 4, id: '8.deco-rid-28', multiple: true },
          { name: 'Élastique : ajout / remplacement', price: 7, liningUnitPrice: 1, id: '8.deco-rid-29', multiple: true },
        ],
      },
      {
        name: 'Coussins',
        items: [
          { name: 'Coussin (moins de 45 cm)', price: 14, id: '8.deco-cou-1', multiple: true },
          { name: 'Coussin (plus de 45 cm)', price: 18, id: '8.deco-cou-2', multiple: true },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 15, liningUnitPrice: 3, id: '8.deco-cou-3', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', price: 20, liningUnitPrice: 3, id: '8.deco-cou-9', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 17, liningUnitPrice: 3, id: '8.deco-cou-4', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', price: 24, liningUnitPrice: 5, id: '8.deco-cou-11', multiple: true },
          { name: 'Déchirure : réparation', price: 10, id: '8.deco-cou-5', multiple: true },
          { name: 'Passant (x1) : ajout / remplacement', price: 4, id: '8.deco-cou-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 4, id: '8.deco-cou-6', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 10, id: '8.deco-cou-7', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 15, id: '8.deco-cou-19', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 3, liningUnitPrice: 1, id: '8.deco-cou-13', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', price: 2, id: '8.deco-cou-14', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 3, id: '8.deco-cou-15', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 1, id: '8.deco-cou-16', multiple: true },
          { name: 'Perlage (5 à 10 perles)', price: 15, id: '8.deco-cou-17', multiple: true },
          { name: 'Pince (x1) : ajout', price: 8, liningUnitPrice: 1, id: '8.deco-cou-18', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 10, id: '8.deco-cou-20', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', price: 10, id: '8.deco-cou-21', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', price: 15, id: '8.deco-cou-22', multiple: true },
          { name: 'Poche complexe (x1) : ajout', price: 20, id: '8.deco-cou-25', multiple: true },
          { name: 'Doublure : ajout / remplacement', price: 9, id: '8.deco-cou-23' },
          { name: 'Fente : ajout / retrait', price: 12, liningUnitPrice: 4, id: '8.deco-cou-24', multiple: true },
          { name: 'Élastique : ajout / remplacement', price: 7, liningUnitPrice: 1, id: '8.deco-cou-26', multiple: true },
        ],
      },
      {
        name: 'Linge de maison',
        items: [
          { name: 'Plus d’1m60 - longueur', price: 20, id: '8.deco-mai-1', multiple: true },
          { name: 'Plus d’1m60 - largeur', price: 20, id: '8.deco-mai-2', multiple: true },
          { name: 'Plus d’1m60 - les deux', price: 40, id: '8.deco-mai-3', multiple: true },
          { name: 'Moins d’1m60 - longueur', price: 15, id: '8.deco-mai-4', multiple: true },
          { name: 'Moins d’1m60 - largeur', price: 15, id: '8.deco-mai-5', multiple: true },
          { name: 'Moins d’1m60 - les deux', price: 30, id: '8.deco-mai-6', multiple: true },
          { name: 'Moins d’1m - longueur', price: 10, id: '8.deco-mai-7', multiple: true },
          { name: 'Moins d’1m - largeur', price: 10, id: '8.deco-mai-8', multiple: true },
          { name: 'Moins d’1m - les deux', price: 20, id: '8.deco-mai-9', multiple: true },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 15, liningUnitPrice: 3, id: '8.deco-mai-10', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', price: 20, liningUnitPrice: 3, id: '8.deco-mai-19', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 17, liningUnitPrice: 3, id: '8.deco-mai-11', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', price: 24, liningUnitPrice: 5, id: '8.deco-mai-21', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 4, id: '8.deco-mai-12', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 10, id: '8.deco-mai-13', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 15, id: '8.deco-mai-26', multiple: true },
          { name: 'Déchirure : réparation', price: 10, id: '8.deco-mai-14', multiple: true },
          { name: 'Passant (x1) : ajout / remplacement', price: 4, id: '8.deco-mai-22', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 3, liningUnitPrice: 1, id: '8.deco-mai-23', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', price: 2, id: '8.deco-mai-15', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 3, id: '8.deco-mai-17', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 1, id: '8.deco-mai-16', multiple: true },
          { name: 'Perlage (5 à 10 perles)', price: 15, id: '8.deco-mai-24', multiple: true },
          { name: 'Pince (x1) : ajout', price: 8, liningUnitPrice: 1, id: '8.deco-mai-25', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 10, id: '8.deco-mai-27', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', price: 10, id: '8.deco-mai-28', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', price: 15, id: '8.deco-mai-29', multiple: true },
          { name: 'Poche complexe (x1) : ajout', price: 20, id: '8.deco-mai-30', multiple: true },
          { name: 'Doublure : ajout / remplacement', price: 35, id: '8.deco-mai-31' },
          { name: 'Fente : ajout / retrait', price: 12, liningUnitPrice: 4, id: '8.deco-mai-32', multiple: true },
          { name: 'Élastique : ajout / remplacement', price: 7, liningUnitPrice: 1, id: '8.deco-mai-33', multiple: true },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 10, id: '8.deco-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 15, id: '8.deco-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 45, id: '8.deco-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', price: 80, id: '8.deco-bro-11', multiple: true },
          { name: 'Dessin creux forme simple < 5cm', price: 10, id: '8.deco-bro-4', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, id: '8.deco-bro-5', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, id: '8.deco-bro-6', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, id: '8.deco-bro-12', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, id: '8.deco-bro-13', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, id: '8.deco-bro-14', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, id: '8.deco-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, id: '8.deco-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, id: '8.deco-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, id: '8.deco-bro-10', multiple: true },
        ],
      },
    ],
  },

  {
    name: 'Robe de mariée',
    slug: 'mari',
    hasMostCommonCategory: true,
    types: [],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Côtés (x2)', price: 63, liningUnitPrice: 28, id: '8.mari-cin-1' },
          { name: 'Épaules (x2)', price: 42, liningUnitPrice: 8, id: '8.mari-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', price: 49, id: '8.mari-cin-3' },
          { name: 'Manches (x2 - boutonnière)', price: 63, id: '8.mari-cin-4' },
          { name: 'Bustier / taille', price: 70, liningUnitPrice: 8, id: '8.mari-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', price: 63, liningUnitPrice: 8, id: '8.mari-cin-6' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Côtés (x2)', price: 35, liningUnitPrice: 12, id: '8.mari-ela-1' },
          { name: 'Épaules (x2)', price: 42, liningUnitPrice: 8, id: '8.mari-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', price: 49, id: '8.mari-ela-3' },
          { name: 'Manches (x2 - boutonnière)', price: 63, id: '8.mari-ela-4' },
          { name: 'Bustier / taille', price: 70, liningUnitPrice: 8, id: '8.mari-ela-5' },
          { name: 'Dos', price: 63, liningUnitPrice: 8, id: '8.mari-ela-6' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', price: 63, liningUnitPrice: 13, id: '8.mari-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet à volants', price: 84, liningUnitPrice: 15, id: '8.mari-rac-2' },
          { name: 'Ourlet revers', price: 65, liningUnitPrice: 13, id: '8.mari-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', price: 23, id: '8.mari-rac-4' },
          { name: 'Manches (x2 - boutonnière)', price: 32, id: '8.mari-rac-5' },
          { name: 'Bretelles (x2)', price: 28, id: '8.mari-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur simple', price: 63, liningUnitPrice: 13, id: '8.mari-ral-1' },
          { name: 'Longueur à volants', price: 84, liningUnitPrice: 15, id: '8.mari-ral-2' },
          { name: 'Ourlets (x2) : suppression des revers', price: 59, liningUnitPrice: 11, id: '8.mari-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', price: 23, id: '8.mari-ral-4' },
          { name: 'Manches (x2 - boutonnière)', price: 32, id: '8.mari-ral-5' },
          { name: 'Bretelles (x2)', price: 28, id: '8.mari-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 20, id: '8.mari-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 28, id: '8.mari-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 52, id: '8.mari-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', price: 110, id: '8.mari-bro-11', multiple: true },
          { name: 'Dessin creux forme simple < 5cm', price: 18, id: '8.mari-bro-4', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 25, id: '8.mari-bro-5', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 35, id: '8.mari-bro-6', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 25, id: '8.mari-bro-12', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 35, id: '8.mari-bro-13', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 45, id: '8.mari-bro-14', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 48, id: '8.mari-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 80, id: '8.mari-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 71, id: '8.mari-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 200, id: '8.mari-bro-10', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', price: 69, id: '8.mari-div-1' },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 49, liningUnitPrice: 13, id: '8.mari-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', price: 56, liningUnitPrice: 12, id: '8.mari-div-19', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', price: 56, liningUnitPrice: 12, id: '8.mari-div-3', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', price: 63, liningUnitPrice: 15, id: '8.mari-div-21', multiple: true },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', price: 35, liningUnitPrice: 8, id: '8.mari-div-25' },
          { name: 'Ceinture : ajout / remplacement', price: 42, id: '8.mari-div-5', multiple: true },
          { name: 'Fente : ajout / retrait', price: 29, liningUnitPrice: 6, id: '8.mari-div-6', multiple: true },
          { name: 'Pince (x1) : ajout', price: 25, liningUnitPrice: 5, id: '8.mari-div-7', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 15, id: '8.mari-div-8', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', price: 18, id: '8.mari-div-22', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', price: 22, id: '8.mari-div-23', multiple: true },
          { name: 'Poche complexe (x1) : ajout', price: 27, id: '8.mari-div-24', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 10, liningUnitPrice: 2, id: '8.mari-div-10', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', price: 4, id: '8.mari-div-11', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 5, id: '8.mari-div-12', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 2, id: '8.mari-div-26', multiple: true },
          { name: 'Déchirure : réparation', price: 20, liningUnitPrice: 8, id: '8.mari-div-13', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant (x1) : ajout / remplacement', price: 10, id: '8.mari-div-14', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 11, id: '8.mari-div-15', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', price: 20, id: '8.mari-div-16', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 35, id: '8.mari-div-17', multiple: true },
          { name: 'Perlage (5 à 10 perles)', price: 35, id: '8.mari-div-27', multiple: true },
          { name: 'Élastique : ajout / remplacement', price: 22, liningUnitPrice: 3, id: '8.mari-div-28', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Accessoires',
    slug: 'acce',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'habillé' },
    ],
    categories: [
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '8.acce-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '8.acce-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '8.acce-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '8.acce-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 10, id: '8.acce-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 15, id: '8.acce-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '8.acce-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 80, id: '8.acce-bro-22', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'SIMPLE', id: '8.acce-bro-7', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'SIMPLE', id: '8.acce-bro-8', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'SIMPLE', id: '8.acce-bro-9', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '8.acce-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '8.acce-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '8.acce-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '8.acce-bro-13', multiple: true },

          { name: 'Dessin creux forme simple < 5cm', price: 10, type: 'COMPLEX', id: '8.acce-bro-14', multiple: true },
          { name: 'Dessin creux forme simple < 12cm', price: 17, type: 'COMPLEX', id: '8.acce-bro-15', multiple: true },
          { name: 'Dessin creux forme simple < 25cm', price: 25, type: 'COMPLEX', id: '8.acce-bro-16', multiple: true },

          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'COMPLEX', id: '8.acce-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'COMPLEX', id: '8.acce-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'COMPLEX', id: '8.acce-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'COMPLEX', id: '8.acce-bro-20', multiple: true },

          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'SIMPLE', id: '8.acce-bro-23', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'SIMPLE', id: '8.acce-bro-24', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'SIMPLE', id: '8.acce-bro-25', multiple: true },
          { name: 'Dessin creux complexe < 5cm', price: 15, type: 'COMPLEX', id: '8.acce-bro-26', multiple: true },
          { name: 'Dessin creux complexe < 12cm', price: 25, type: 'COMPLEX', id: '8.acce-bro-27', multiple: true },
          { name: 'Dessin creux complexe < 25cm', price: 40, type: 'COMPLEX', id: '8.acce-bro-28', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 15, liningUnitPrice: 3, id: '8.acce-div-1', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 20, liningUnitPrice: 3, id: '8.acce-div-26', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, liningUnitPrice: 3, id: '8.acce-div-2', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE', price: 24, liningUnitPrice: 5, id: '8.acce-div-28', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 3, liningUnitPrice: 1, id: '8.acce-div-33', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE', price: 2, id: '8.acce-div-3', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '8.acce-div-21', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '8.acce-div-19', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '8.acce-div-35', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '8.acce-div-4', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE', price: 4, id: '8.acce-div-49', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.acce-div-5', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '8.acce-div-6', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '8.acce-div-7', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '8.acce-div-8', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE', price: 15, id: '8.acce-div-23', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.acce-div-37', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.acce-div-38', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.acce-div-39', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.acce-div-40', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 9, id: '8.acce-div-45' },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 12, liningUnitPrice: 4, id: '8.acce-div-47', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '8.acce-div-51', multiple: true },

          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 22, liningUnitPrice: 3, id: '8.acce-div-9', multiple: true },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 26, liningUnitPrice: 4, id: '8.acce-div-30', multiple: true },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '8.acce-div-10', multiple: true },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'COMPLEX', price: 29, liningUnitPrice: 7, id: '8.acce-div-32', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '8.acce-div-34', multiple: true },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'COMPLEX', price: 3, id: '8.acce-div-11', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '8.acce-div-22', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 1, id: '8.acce-div-20', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 12, liningUnitPrice: 1, id: '8.acce-div-36', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '8.acce-div-12', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'COMPLEX', price: 8, id: '8.acce-div-50', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.acce-div-13', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 6, id: '8.acce-div-14', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 12, id: '8.acce-div-15', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 17, id: '8.acce-div-16', multiple: true },
          { name: 'Perlage (5 à 10 perles)', type: 'COMPLEX', price: 17, id: '8.acce-div-24', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 12, id: '8.acce-div-41', multiple: true },
          { name: 'Poche plate simple (x1) : ajout', type: 'COMPLEX', price: 15, id: '8.acce-div-42', multiple: true },
          { name: 'Poche intérieure classique (x1) : ajout', type: 'COMPLEX', price: 19, id: '8.acce-div-43', multiple: true },
          { name: 'Poche complexe (x1) : ajout', type: 'COMPLEX', price: 22, id: '8.acce-div-44', multiple: true },
          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 12, id: '8.acce-div-46' },
          { name: 'Fente : ajout / retrait', type: 'COMPLEX', price: 18, liningUnitPrice: 6, id: '8.acce-div-48', multiple: true },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '8.acce-div-52', multiple: true },
        ],
      },
    ],
  },

  {
    name: 'Création de totebag',
    slug: 'crea_tote',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - classique : côtés < 50 cm' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - classique : côtés < 50 cm' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand : côtés > 51 cm' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand : côtés > 51 cm' },
    ],
    autoId: true,
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Classique : côtés < 50 cm', type: 'SIMPLE_SMALL', price: 10 },
          { name: 'Grand : côtés > 51 cm', type: 'SIMPLE_BIG', price: 15 },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', type: 'SIMPLE_SMALL', price: 15 },
          { name: 'Chaîne + oeillets', type: 'SIMPLE_BIG', price: 15 },
          { name: 'Tissu', type: 'SIMPLE_SMALL', price: 2 },
          { name: 'Tissu', type: 'SIMPLE_BIG', price: 3 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_SMALL', price: 2 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_BIG', price: 2 },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
        ],
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', type: 'SIMPLE_SMALL', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_BIG', price: 9 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Molleton', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Molleton', type: 'SIMPLE_BIG', price: 7 },
          { name: 'Thermocollant', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Thermocollant', type: 'SIMPLE_BIG', price: 7 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_SMALL', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_BIG', price: 8, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_SMALL', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_BIG', price: 12, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_SMALL', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_BIG', price: 7, autoComplexFactor: 1 },
        ],
      },
    ],
  },

  {
    name: 'Création de sac',
    slug: 'crea_sac',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit : côtés < 35 cm' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit : côtés < 35 cm' },
      { slug: 'SIMPLE_MEDIUM', name: 'Forme simple - moyen : 36 cm < côtés < 50 cm' },
      { slug: 'COMPLEX_MEDIUM', name: 'Forme originale - moyen : 36 cm < côtés < 50 cm' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple : côtés > 51 cm' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale : côtés > 51 cm' },
    ],
    autoId: true,
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit : côtés < 35 cm', type: 'SIMPLE_SMALL', price: 10 },
          { name: 'Moyen : 36 cm < côtés < 50 cm', type: 'SIMPLE_MEDIUM', price: 15 },
          { name: 'Grand : côtés > 51 cm', type: 'SIMPLE_BIG', price: 20 },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', type: 'SIMPLE_SMALL', price: 15 },
          { name: 'Chaîne + oeillets', type: 'SIMPLE_MEDIUM', price: 15 },
          { name: 'Chaîne + oeillets', type: 'SIMPLE_BIG', price: 15 },
          { name: 'Tissu', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Tissu', type: 'SIMPLE_MEDIUM', price: 5 },
          { name: 'Tissu', type: 'SIMPLE_BIG', price: 5 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_MEDIUM', price: 5 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_BIG', price: 5 },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
        ],
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', type: 'SIMPLE_SMALL', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_MEDIUM', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_BIG', price: 9 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Molleton', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Molleton', type: 'SIMPLE_MEDIUM', price: 6 },
          { name: 'Molleton', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Thermocollant', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Thermocollant', type: 'SIMPLE_MEDIUM', price: 6 },
          { name: 'Thermocollant', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
          { name: 'Clous de protection', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Clous de protection', type: 'SIMPLE_MEDIUM', price: 7 },
          { name: 'Clous de protection', type: 'SIMPLE_BIG', price: 10 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_MEDIUM', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_SMALL', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_BIG', price: 8, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_SMALL', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_MEDIUM', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_BIG', price: 12, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_SMALL', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_BIG', price: 7, autoComplexFactor: 1 },
        ],
      },
    ],
  },

  {
    name: 'Création de pochette',
    slug: 'crea_poch',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit (15x10cm)' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit (15x10cm)' },
      { slug: 'SIMPLE_MEDIUM', name: 'Forme simple - moyen (20x20cm)' },
      { slug: 'COMPLEX_MEDIUM', name: 'Forme originale - moyen (20x20cm)' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand (>25cm)' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand (>25cm)' },
    ],
    autoId: true,
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit 15x10cm', type: 'SIMPLE_SMALL', price: 7 },
          { name: 'Moyen 20x20cm', type: 'SIMPLE_MEDIUM', price: 12 },
          { name: 'Grand > 25cm', type: 'SIMPLE_BIG', price: 18 },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', type: 'SIMPLE_SMALL', price: 15 },
          { name: 'Chaîne + oeillets', type: 'SIMPLE_MEDIUM', price: 15 },
          { name: 'Chaîne + oeillets', type: 'SIMPLE_BIG', price: 15 },
          { name: 'Tissu', type: 'SIMPLE_SMALL', price: 2 },
          { name: 'Tissu', type: 'SIMPLE_MEDIUM', price: 2 },
          { name: 'Tissu', type: 'SIMPLE_BIG', price: 2 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_SMALL', price: 2 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_MEDIUM', price: 2 },
          { name: 'Autre / fourni par le client', type: 'SIMPLE_BIG', price: 2 },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
        ],
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', type: 'SIMPLE_SMALL', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_MEDIUM', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_BIG', price: 9 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Molleton', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Molleton', type: 'SIMPLE_MEDIUM', price: 7 },
          { name: 'Molleton', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Thermocollant', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Thermocollant', type: 'SIMPLE_MEDIUM', price: 7 },
          { name: 'Thermocollant', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_MEDIUM', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_MEDIUM', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_MEDIUM', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_MEDIUM', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_SMALL', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_MEDIUM', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_BIG', price: 8, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_SMALL', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_MEDIUM', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_BIG', price: 12, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_SMALL', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_MEDIUM', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_BIG', price: 7, autoComplexFactor: 1 },
        ],
      },
    ],
  },

  {
    name: 'Création de housse de coussin',
    slug: 'crea_hous',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit (<45cm)' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit (<45cm)' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand (>45cm)' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand (>45cm)' },
    ],
    autoId: true,
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit < 45cm', type: 'SIMPLE_SMALL', price: 14 },
          { name: 'Grand > 45cm', type: 'SIMPLE_BIG', price: 18 },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: [
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
        ],
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', type: 'SIMPLE_SMALL', price: 9 },
          { name: 'Doublure', type: 'SIMPLE_BIG', price: 9 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche plate simple (x1) : ajout', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Poche extérieure', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Poche complexe (x1) : ajout', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Molleton', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Molleton', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Thermocollant', type: 'SIMPLE_SMALL', price: 5 },
          { name: 'Thermocollant', type: 'SIMPLE_BIG', price: 8 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Zip < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 20, autoComplexFactor: 1 },
          { name: 'Zip long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 20, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible < 25cm fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 17, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_SMALL', price: 24, autoComplexFactor: 1 },
          { name: 'Zip invisible long fourni par le Tilliste : ajout / remplacement / retrait', type: 'SIMPLE_BIG', price: 24, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_SMALL', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton (x1) fourni par le Tilliste : ajout / remplacement', type: 'SIMPLE_BIG', price: 2, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 3, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 1, autoComplexFactor: 1 },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 1, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Passant (x1) : ajout / remplacement', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_SMALL', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE_BIG', price: 4, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_SMALL', price: 10, autoComplexFactor: 1 },
          { name: 'Déchirure : réparation', type: 'SIMPLE_BIG', price: 10, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_SMALL', price: 15, autoComplexFactor: 1 },
          { name: 'Perlage (5 à 10 perles)', type: 'SIMPLE_BIG', price: 15, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_SMALL', price: 8, autoComplexFactor: 1 },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE_BIG', price: 8, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_SMALL', price: 12, autoComplexFactor: 1 },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE_BIG', price: 12, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_SMALL', price: 7, autoComplexFactor: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE_BIG', price: 7, autoComplexFactor: 1 },
        ],
      },
    ],
  },

  {
    name: 'Masque',
    slug: 'mask',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [],
    categories: [
      {
        name: 'Fabrication',
        items: [
          { name: 'Masque en tissu', type: 'SIMPLE', price: 10, id: '8.mask-fab-1' },
          { name: 'Masque en tissu adulte bleu avec lanières', type: 'SIMPLE', price: 10, id: '8.mask-fab-2' },
          { name: 'Masque en tissu adulte beige avec lanières', type: 'SIMPLE', price: 10, id: '8.mask-fab-3' },
          { name: 'Masque en tissu enfant bleu avec lanières', type: 'SIMPLE', price: 10, id: '8.mask-fab-4' },
          { name: 'Masque en tissu enfant beige avec lanières', type: 'SIMPLE', price: 10, id: '8.mask-fab-5' },
          { name: 'Masque en tissu adulte bleu avec élastiques', type: 'SIMPLE', price: 10, id: '8.mask-fab-6' },
          { name: 'Masque en tissu adulte beige avec élastiques', type: 'SIMPLE', price: 10, id: '8.mask-fab-7' },
          { name: 'Masque en tissu enfant bleu avec élastiques', type: 'SIMPLE', price: 10, id: '8.mask-fab-8' },
          { name: 'Masque en tissu enfant beige avec élastiques', type: 'SIMPLE', price: 10, id: '8.mask-fab-9' },
        ],
      },
    ],
  },
];

export default rawPricing;
