import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../withAppContext';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { getPricingInverse } from '../../core/pricings';
import { upcyclingPricingInverse } from '../../services/pricing';

const ClothContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const AlterationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AlterationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;
`;

const StyledContainer = styled.p`
  margin: 0px;
  width: 80%;
  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 82%;
    margin-bottom: 0px;
    margin-left: 0px;
  }
`;

const TextSummary = styled.span`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin: 0px 4px 0px 0px;
  color: ${({ navy }) => navy ? colors.navy : colors.lightGrey4};
  ${({ bold }) => bold && `font-weight: 500; color: ${colors.navy};`}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const getClothItemsByTypeOfWork = (cloth) => {
  const clothAlterationItems = cloth?.items?.filter((item) => item.quantity > 0 && !item.id.includes('-bro-') && !item.id.includes('.crea_')) || [];
  const clothEmbroideryItems = cloth?.items?.filter((item) => item.quantity > 0 && item.id.includes('-bro-')) || [];
  const clothUpcyclingItems = cloth?.upcyclingItems || [];
  const clothAccessoryCreationItems = cloth?.items?.filter((item) => item.quantity > 0 && item.id.includes('.crea_')) || [];
  return ([
    { slug: 'alteration', items: clothAlterationItems },
    { slug: 'embroidery', items: clothEmbroideryItems },
    { slug: 'transformation', items: clothUpcyclingItems },
    { slug: 'creation', items: clothAccessoryCreationItems },
  ]);
};

const OrderSummaryCloth = ({ context: { isMobile }, cloth, pricingVersion }) => {
  const clothItems = getClothItemsByTypeOfWork(cloth);
  return (
    <div>
      {Object.values(clothItems).map(({ slug, items }) => {
        if (items.length === 0) return <div key={slug} />;
        return (
          <ClothContainer key={slug}>
            <AlterationsContainer>
              {items.map((item) => {
                const refItem = slug !== 'transformation' ? getPricingInverse(pricingVersion)[item.id] : upcyclingPricingInverse[item.id];
                if (!refItem) return null;
                const { name, category } = refItem;
                const basePrice = item.price * (item.quantity || 1);
                const liningPrice = cloth.liningsNumber * (item.liningUnitPrice || 0) * (item.quantity || 1);
                const price = basePrice + liningPrice;
                const nameToDisplay = name.toLowerCase();
                return (
                  <AlterationContainer key={item.id}>
                    {!isMobile && (
                      <StyledContainer>
                        <TextSummary>
                          {`- ${category}`}
                        </TextSummary>
                        <TextSummary>
                          {(item.quantity > 1) ? `${item.quantity}X - ${nameToDisplay}` : nameToDisplay}
                        </TextSummary>
                      </StyledContainer>
                    )}
                    {isMobile && (
                      <StyledContainer>
                        <TextSummary>
                          {`- ${category}`}
                          {' '}
                          {(item.quantity > 1) ? `${item.quantity}X - ${nameToDisplay}` : nameToDisplay}
                        </TextSummary>
                      </StyledContainer>
                    )}
                    <TextSummary bold navy>
                      {`${price}€`}
                    </TextSummary>
                  </AlterationContainer>
                );
              })}
              {(cloth.liningsNumber > 0)
                && (
                  <AlterationContainer>
                    <TextSummary>
                      {`(doublure(s) : ${cloth.liningsNumber})`}
                    </TextSummary>
                  </AlterationContainer>
                )}
            </AlterationsContainer>
          </ClothContainer>
        );
      })}
    </div>
  );
};

OrderSummaryCloth.propTypes = {
  cloth: PropTypes.shape({
    liningsNumber: PropTypes.number,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  pricingVersion: PropTypes.number,
};

OrderSummaryCloth.defaultProps = {
  pricingVersion: 1,
};

export default withAppContext(OrderSummaryCloth);
