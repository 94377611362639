import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getTotalPaid,
  getMinOrderAmountFee,
  getTotalDiscount,
} from '../../core/services/orderPrice';
import OrderSummaryCloth from './OrderSummaryCloth';
import { pricingInverse } from '../../services/pricing';
import OrderSummaryPartnerProduct from '../TilliUptrade/OrderSummaryPartnerProduct';
import OrderSummaryRetailProduct from './OrderSummaryRetailProduct';

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ marginTop }) => marginTop && 'margin-top: 80px;'}
  ${({ noMarginBottom }) => !noMarginBottom && 'margin-bottom: 15px;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    ${({ marginTop }) => marginTop && 'margin-top: 60px;'}
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin: 0px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 12px;'}
  ${({ marginTop }) => marginTop && 'margin-top: 20px;'}
  color: ${({ theme, grey }) => grey ? theme.colors.lightGrey4 : theme.colors.navy};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    ${({ marginBottom }) => marginBottom && 'margin-bottom: 8px;'}
    ${({ marginTop }) => marginTop && 'margin-top: 18px;'}
  }
`;

const Line = styled.div`
  width: 100%;
  border-top: 0.75px solid ${({ theme }) => theme.colors.lightGrey4};
  margin: ${({ smallMarginTop }) => smallMarginTop ? '0' : '20'}px 0px 20px;
  ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0px;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: ${({ smallMarginTop }) => smallMarginTop ? '8' : '18'}px 0px 18px;
    ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0px;'}
  }
`;

const clothTypesOfWorks = {
  alteration: 'retoucher',
  embroidery: 'broder',
  transformation: 'upcycler',
  creation: 'créer',
};

const getClothName = (cloth) => {
  let clothName = '';
  if (!cloth.name) {
    try {
      clothName = pricingInverse[cloth.items[0].id].cloth;
    } catch (e) {
      return null;
    }
  } else {
    clothName = cloth.name;
  }

  if (cloth.typesOfWork?.length > 0) {
    clothName = `${clothName} à ${cloth.typesOfWork
      .reduce((typesOfWork, typeOfWork, index) => {
        let linkWord = '';
        if (index !== cloth.typesOfWork.length - 1) linkWord = ', ';
        if (index === cloth.typesOfWork.length - 2) linkWord = ' et ';
        return (`${typesOfWork}${clothTypesOfWorks[typeOfWork]}${linkWord}`);
      }, '')}`;
  }
  clothName = clothName.replace(' ()', '');
  return clothName;
};

const renderOrderClothes = (order) => (order.clothes?.map((cloth) => {
  if (!getClothName(cloth)) return <div key={cloth._id} />;
  return (
    <div key={cloth._id}>
      <Text marginBottom marginTop>{getClothName(cloth)}</Text>
      <OrderSummaryCloth
        cloth={cloth}
        pricingVersion={order.pricingVersion}
      />
    </div>
  );
}));

const renderOrderPartnerProducts = (order) => (order.partnerProducts?.map((partnerProduct) => (
  <OrderSummaryPartnerProduct key={partnerProduct._id} partnerProduct={partnerProduct} />
)));

const renderOrderRetailProducts = (order) => {
  if ((order.retailProducts || [])?.length === 0) return null;
  return (
    <>
      <Text marginBottom marginTop>Produits</Text>
      {order.retailProducts.map((retailProduct) => (
        <OrderSummaryRetailProduct key={retailProduct._id} retailProduct={retailProduct} />
      ))}
    </>
  );
};

const renderPriceRow = (label, value, { hasMarginTop = false, hasNoMarginBottom = false, isGrey = false }) => (
  <TextContainer marginTop={hasMarginTop} noMarginBottom={hasNoMarginBottom}>
    <Text grey={isGrey}>{label}</Text>
    <Text grey={isGrey}>{`${value}€`}</Text>
  </TextContainer>
);

const renderMinOrderAmountFee = (order, minOrderAmountFee) => {
  if (minOrderAmountFee <= 0) return null;
  return renderPriceRow('Minimum de commande', order.minOrderAmount, { hasMarginTop: true, isGrey: true });
};

const renderDeliveryFee = (order, minOrderAmountFee) => (
  renderPriceRow(`Frais de ${order.process === 'PARTNER_PRODUCT' ? 'livraison' : 'déplacement'}`, order.deliveryFee, { hasMarginTop: minOrderAmountFee === 0 })
);

const renderDiscount = (order) => {
  const discount = getTotalDiscount(order);
  if (discount === 0) return null;
  return renderPriceRow('Réduction', `${-discount}`);
};

const renderTotal = (order) => {
  const totalPaid = getTotalPaid(order);
  return renderPriceRow('Prix total', totalPaid, { hasNoMarginBottom: true });
};

const OrderSummary = ({ order }) => {
  const minOrderAmountFee = getMinOrderAmountFee(order);
  return (
    <div>
      <Line smallMarginTop noMarginBottom />
      {renderOrderClothes(order)}
      {renderOrderRetailProducts(order)}
      {renderOrderPartnerProducts(order)}
      {renderMinOrderAmountFee(order, minOrderAmountFee)}
      {renderDeliveryFee(order, minOrderAmountFee)}
      {renderDiscount(order)}
      <Line />
      {renderTotal(order)}
    </div>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})),
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    pricingVersion: PropTypes.number,
    minOrderAmount: PropTypes.number,
    deliveryFee: PropTypes.number,
    process: PropTypes.string,
  }).isRequired,
};

export default OrderSummary;
