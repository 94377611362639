import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import callApi from '../../services/api';
import OutInSideClick from '../home/v2/OutInSideClick';

const Container = styled.div`
  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 480px;
  height: 276px;
  margin: auto;
  padding: 26px 36px;

  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  box-shadow: 0px 4px 8px ${colors.myAccountMenu};

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 80vw;
    padding: 22px 17px;
    height: 283px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 18px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

const Button = styled(ButtonV3)`
  margin-top: 15px;
  min-width: unset;
  width: 135px;
  min-height: unset;
  height: 45px;
  align-self: flex-end;
  font-size: 14px;
  ${(props) => !props.isAvailable && `
    background-color: ${colors.lightGrey3};
    border-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;`
}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0px 0px;
  }
`;

const RatesLine = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    justify-content: space-around;
  }
`;

const Rate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 26px;
  font-weight: 500;
  cursor: pointer;
  ${({ isSelected }) => isSelected
    ? `background-color: ${colors.navy};
    color: ${colors.white};
    `
    : `background-color: ${colors.white};
    color: ${colors.navy};`
}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 28px;
    height: 28px;
    font-size: 22px;
  }
`;

const CommentArea = styled.textarea`
  height: 85px;
  width: calc(100% - 29px);
  outline: none;
  font-family: Roboto;
  font-size: 18px;
  color: ${colors.navy};
  border: 0.5px solid ${colors.navy};
  padding: 12px;
  &::placeholder {
    color: ${colors.navy};
    opacity: 0.2;
  }
  @media (max-width: ${mobileThresholdPixels}) {
    height: 89px;
    padding: 9px;
    width: calc(100% - 20px);
  }
`;

const rates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class PopupOrderFeedback extends Component {
  constructor() {
    super();
    this.state = {
      customerFeedbackRating: undefined,
      customerFeedbackComment: '',
    };
    this.selectRate = this.selectRate.bind(this);
    this.writeComment = this.writeComment.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  selectRate(customerFeedbackRating) {
    this.setState({ customerFeedbackRating });
  }

  writeComment(customerFeedbackComment) {
    this.setState({ customerFeedbackComment });
  }

  submitFeedback() {
    const { closePopup, orderId, customerContext: { addCustomerFeedbackToOrder } } = this.props;
    const { customerFeedbackRating, customerFeedbackComment } = this.state;
    const customerFeedbackDate = new Date();
    return callApi(`orders/${orderId}/addCustomerFeedback`, 'put', {
      customerFeedbackDate, customerFeedbackRating, customerFeedbackComment,
    })
      .then(() => {
        closePopup();
        addCustomerFeedbackToOrder(orderId, customerFeedbackRating);
      })
      .catch(() => null);
  }

  render() {
    const { closePopup } = this.props;
    const { customerFeedbackRating, customerFeedbackComment } = this.state;
    const isDone = !!customerFeedbackRating;
    return (
      <OutInSideClick handleClickOutside={closePopup}>
        <Container>
          <Title>Donner votre avis</Title>
          <RatesLine>
            {rates.map((rate) => (
              <Rate
                key={rate}
                isSelected={rate === customerFeedbackRating}
                onClick={() => this.selectRate(rate)}
              >
                {rate}
              </Rate>
            ))}
          </RatesLine>
          <CommentArea
            placeholder="Écrire un avis..."
            value={customerFeedbackComment}
            onChange={({ target: { value } }) => this.writeComment(value)}
          />
          <Button isAvailable={isDone} navy onClick={this.submitFeedback}>Envoyer</Button>
        </Container>
      </OutInSideClick>
    );
  }
}

PopupOrderFeedback.propTypes = {
  closePopup: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  customerContext: PropTypes.shape({
    addCustomerFeedbackToOrder: PropTypes.func.isRequired,
  }).isRequired,
};

export default withCustomerContext(PopupOrderFeedback);
