/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import iconRule from '../../assets/tilliUptrade/rule.png';

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const AlterationsRow = styled(TotalRow)`
  justify-content: flex-start;
  align-items: start;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: 2.5px 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  padding: 4px 6px 4px 0px;
`;

const Name = styled.span`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 10px;
`;

const renderPartnerProductName = ({ partnerProduct }) => (
  <TotalRow marginBottom="10px">
    <Name>{partnerProduct.name}</Name>
    <span>{`${Math.round(partnerProduct.price)}€`}</span>
  </TotalRow>
);

renderPartnerProductName.propTypes = {
  partnerProduct: PropTypes.shape({}).isRequired,
};

const renderPartnerProductSize = ({ partnerProduct, sizePropName = 'lengthStr' }) => {
  const isLength = sizePropName === 'lengthStr';
  const label = isLength ? 'Métrage' : 'Largeur';
  return (
    <AlterationsRow>
      <Icon src={iconRule} alt={label} />
      <span>{`${label} : ${partnerProduct[sizePropName]}`}</span>
    </AlterationsRow>
  );
};

const OrderSummaryPartnerProduct = ({
  partnerProduct,
}) => (
  <>
    {renderPartnerProductName({ partnerProduct })}
    {renderPartnerProductSize({ partnerProduct })}
    {renderPartnerProductSize({ partnerProduct, sizePropName: 'width' })}
  </>
);

OrderSummaryPartnerProduct.propTypes = {
  partnerProduct: PropTypes.shape({}).isRequired,
};

export default OrderSummaryPartnerProduct;
