import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../home/v3/styledComponents';

const RetailProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 4px;
`;

const StyledContainer = styled.p`
  margin: 0px;
  width: 80%;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: 82%;
    margin-bottom: 0px;
    margin-left: 0px;
  }
`;

const Text = styled.span`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin: 0px 4px 0px 0px;
  color: ${({ navy }) => navy ? colors.navy : colors.lightGrey4};
  ${({ bold }) => bold && `font-weight: 500; color: ${colors.navy};`}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const OrderSummaryRetailProduct = ({ retailProduct }) => (
  <RetailProductContainer>
    <StyledContainer>
      <Text>
        {`- ${retailProduct.quantity || 1} x ${retailProduct.name}`}
      </Text>
    </StyledContainer>
    <Text bold navy>{`${(retailProduct.quantity || 1) * retailProduct.price}€`}</Text>
  </RetailProductContainer>
);

OrderSummaryRetailProduct.propTypes = {
  retailProduct: PropTypes.shape({
    quantity: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
};

export default OrderSummaryRetailProduct;
