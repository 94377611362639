import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import withAppContext from '../../withAppContext';
import { getWrittenDate, getGroupedSlotsPerDay } from '../../services/slotsFormatting';
import { Button as ButtonV3 } from '../home/v3/styledComponents';
import OrderSummary from './OrderSummary';
import PopupOrderFeedback from './PopupOrderFeedback';
import momentFr from '../../tilli-components/src/config/moment.fr';
import routesMap from '../../Routes';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const OrderDetailsContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.navy};
    padding-bottom: 13px;
  }
`;

const Container = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => marginTop && 'margin-top: 26px;'}
  ${({ fixWidth }) => fixWidth ? 'width: 543px;' : 'flex-grow: 1;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column;'}
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const InfoTitle = styled.span`
  font-weight: 500;
  margin: 0px 5px 0px 0px;
`;

const InfoValue = styled.span`
  color: ${({ theme }) => theme.colors.lightGrey4};
  margin: 0px;
`;

const OtherInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 19px 31px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: 26px 29px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 22px 0px 18px;
    padding: 26px 0px;
  }
`;

const TextLine = styled.p`
  margin: 0px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 20px;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    ${({ marginBottom }) => marginBottom && 'margin-bottom: 10px;'}
  }
`;

const Button = styled(ButtonV3)`
  margin-top: 40px;
  width: 177px;
  min-width: unset;
  height: 44px;
  min-height: unset;
  font-size: 14px;
  line-height: 16px;
  align-self: flex-start;
  ${({ centered }) => centered && 'align-self: center;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 29px 0px ${({ small }) => small ? '21' : '82'}px;
  }
`;

const ContactLine = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGrey4};
  text-align: center;
  margin: 0px 48px 32px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-left: 29px;
    margin-right: 29px;
  }
`;

const A = styled.a`
  color: inherit;
  margin-left: 5px;
  ${(props) => props.noDecoration && 'text-decoration: none;'}
`;

const OpacityContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

const Line = styled.div`
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.colors.lightGrey2};
  margin-top: 59px;
`;

const getFirstRDVPrefixAndSlots = (state, rdv1, rdv1Slots) => {
  const firstRDV = {};
  switch (state) {
    case 'ACTIVATION':
    case 'WAITING_COUTURIER_ANSWER':
    case 'WAITING_COUTURIER_ANSWER_CHANGE_RDV1':
    case 'COUTURIER_REFUSAL':
      firstRDV.prefix = "En cours d'assignation pour";
      firstRDV.slots = rdv1Slots;
      break;
    default:
      if (state === 'ORDER_CONFIRMATION') firstRDV.prefix = 'Confirmé pour';
      firstRDV.slots = [rdv1];
  }
  return firstRDV;
};

const getFirstRDVInfos = (state, rdv1, rdv1Slots) => {
  const { prefix, slots } = getFirstRDVPrefixAndSlots(state, rdv1, rdv1Slots);
  const groupedSlotsPerDay = getGroupedSlotsPerDay(slots);
  return groupedSlotsPerDay
    .map((oneDaySlots) => (
      oneDaySlots.slots.reduce((accLine, slot, index, slotsList) => {
        const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, index, slotsList);
        return rdvEnd ? `${accLine} ${rdvDate} de ${rdvBegin} à ${rdvEnd}` : `${accLine} ${rdvDate} ${rdvBegin}`;
      }, '')).replace(/ ,/g, ','))
    .map((dateLine, index) => {
      if (index === 0) {
        return prefix !== undefined ? `${prefix} ${dateLine.toLowerCase()}` : dateLine;
      }
      return `ou${dateLine.toLowerCase()}`;
    });
};

const getGiveBackInfos = (rdv2, deliverySlot) => {
  const giveBack = {};
  if (rdv2 || deliverySlot) {
    giveBack.slot = rdv2 || deliverySlot;
    giveBack.prefix = rdv2 ? 'Par le Tilliste, ' : 'Par coursier, ';
    const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(giveBack.slot, 0, [giveBack.slot]);
    giveBack.line = `${giveBack.prefix} ${rdvDate.toLowerCase()} de ${rdvBegin} à ${rdvEnd}`;
  }
  return giveBack.line;
};

const getReadjustmentInfos = (rdv3) => {
  const readjustment = {};
  if (rdv3) {
    readjustment.prefix = moment(rdv3.begin).isBefore(moment()) ? 'Prévu ' : '';
    const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(rdv3, 0, [rdv3]);
    readjustment.rdvDate = readjustment.prefix !== '' ? rdvDate.toLowerCase() : rdvDate;
    readjustment.line = `${readjustment.prefix} ${readjustment.rdvDate} de ${rdvBegin} à ${rdvEnd}`;
  }
  return readjustment.line;
};

const getOrderOtherInfos = ({
  process,
  state,
  rdv1, rdv1Slots,
  fullAddress,
  couturier,
  rdv2, deliverySlot,
  rdv3,
}) => {
  const firstRDV = process === 'PARTNER_PRODUCT' ? undefined : getFirstRDVInfos(state, rdv1, rdv1Slots);
  const address = [`${fullAddress.street} ${fullAddress.zipcode} ${fullAddress.locality}`];
  const tilliste = process === 'PARTNER_PRODUCT' ? undefined : [couturier ? couturier.firstname : 'Pas encore assigné'];
  const giveBack = [getGiveBackInfos(rdv2, deliverySlot)];
  const readjustment = [getReadjustmentInfos(rdv3)];

  return [
    { label: '1er rendez-vous', value: firstRDV },
    { label: 'Adresse', value: address },
    { label: 'Tilliste', value: tilliste },
    { label: 'Rendu', value: giveBack },
    { label: 'RDV réajustement', value: readjustment },
  ];
};

const getOrderHasNoFeedback = (order) => (order.state === 'DONE' || order.state === 'WAITING_PAYMENT_COUTURIER') && !order.customerFeedbackRating;

class OrderDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isPopupOpened: false,
    };
  }

  setIsPopupOpened = (isPopupOpened) => {
    this.setState({ isPopupOpened });
  }

  renderMainInfos = (orderHasNoFeedback) => {
    const { order, context: { isMobile } } = this.props;
    const { orderValues, orderRows } = order;
    const mainInfosRows = orderRows.slice(0, 3);
    return (
      <SubContainer marginTop>
        {mainInfosRows.map((info, index) => (
          <TextLine key={info} marginBottom>
            <InfoTitle>{`${info} :`}</InfoTitle>
            <InfoValue>{orderValues[index]}</InfoValue>
          </TextLine>
        ))}
        {!isMobile && (orderHasNoFeedback
          ? <Button navy onClick={() => this.setIsPopupOpened(true)}>Donner un avis</Button>
          : <Line />
        )}
        {!order.cardToken
          && (
            <Button navy centered>
              <A
                href={`${routesMap.Payment.url}/${order._id}/${order.customer._id}`}
                noDecoration
                target="_blank"
              >
                Renseigner votre cb
              </A>
            </Button>
          )}
      </SubContainer>
    );
  }

  renderPopupOrderFeedback = () => {
    const { order } = this.props;
    const { isPopupOpened } = this.state;
    if (!isPopupOpened) return null;
    return (
      <>
        <PopupOrderFeedback
          closePopup={() => this.setIsPopupOpened(false)}
          orderId={order._id}
        />
        <OpacityContainer />
      </>
    );
  }

  render() {
    const { order, context: { isMobile } } = this.props;
    if (!order) return null;
    const orderOtherInfos = getOrderOtherInfos(order);
    const orderHasNoFeedback = getOrderHasNoFeedback(order);
    return (
      <OrderDetailsContainer>
        <Title>Détails de la commande</Title>
        <Container>
          {this.renderMainInfos(orderHasNoFeedback)}
          <SubContainer fixWidth>
            <OtherInfosContainer>
              {orderOtherInfos.filter(({ value }) => value?.length > 0 && value[0] !== undefined).map(({ label, value }) => (
                <InfoContainer key={label} column>
                  <TextLine>
                    <InfoTitle>{`${label} :`}</InfoTitle>
                    <InfoValue>{value[0]}</InfoValue>
                  </TextLine>
                  {value.length > 1 && value.map((line, indexLine) => {
                    if (indexLine === 0) return null;
                    return <InfoValue key={line}>{line}</InfoValue>;
                  })}
                </InfoContainer>
              ))}
              <OrderSummary order={order} />
            </OtherInfosContainer>
            <ContactLine>
              Des remarques sur vos rendez-vous ? N’hésitez pas à nous contacter à :
              <A href="mailto:contact@tilli.fr">contact@tilli.fr</A>
            </ContactLine>
            {isMobile && orderHasNoFeedback
              && <Button navy onClick={() => this.setIsPopupOpened(true)}>Donner un avis</Button>}
          </SubContainer>
        </Container>
        {this.renderPopupOrderFeedback()}
      </OrderDetailsContainer>
    );
  }
}

OrderDetails.propTypes = {
  order: PropTypes.shape({
    process: PropTypes.string,
    orderValues: PropTypes.arrayOf(PropTypes.string),
    orderRows: PropTypes.arrayOf(PropTypes.shape({})),
    customer: PropTypes.shape({
      _id: PropTypes.string,
    }),
    state: PropTypes.string,
    _id: PropTypes.string,
    customerFeedbackRating: PropTypes.number,
    cardToken: PropTypes.string,
  }),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

OrderDetails.defaultProps = {
  order: undefined,
};

export default withAppContext(OrderDetails);
