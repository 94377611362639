import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import withCustomerContext from '../../withCustomerContext';
import routesMap from '../../Routes';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { Link } from './myAccountStyledComponents';
import OutInSideClick from '../home/v2/OutInSideClick';
import DropDownList from './DropDownList';

import triangle from '../../assets/myAccount/triangle.svg';
import arrowDown from '../../assets/myAccount/arrow.down.grey.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 253px;
  width: 253px;
  min-height: 741px;
  margin-left: 31px;
  padding-left: 53px;
  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: flex-end;
    width: 100%;
    min-width: unset;
    min-height: unset;
    height: 74px;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 32px;
    background-color: ${colors.white};
    font-size: 22px;
    font-weight: 500;
  }
`;

const MobileTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
`;

const Title = styled.p`
  font-weight: 500;
  margin-top: 27px;
  margin-bottom: 12px;
`;

const Line = styled.hr`
  width: 35px;
  height: 0.5px;
  border: 0px;
  background-color: ${colors.navy};
  margin: 0px 0px 51px;
`;

const MenuItem = styled.div`
  display: flex;
  margin: 0px 0px 18px;
  ${({ bold }) => bold && 'font-weight: 500;'}
  cursor: pointer;
`;

const ImgDesktop = styled.img`
  margin-right: 7px;
`;

const ImgMobile = styled.img`
  margin: auto 7px;
  width: 8px;
  height: 5px;
`;

const EmptyDiv = styled.div`
  margin: auto 7px;
  width: 8px;
  height: 5px;
`;

const SelectedItem = styled.div`
  width: 175px;
  text-align: center;
`;

const menuItems = [
  {
    index: 0,
    title: 'Récapitulatif',
    slug: 'MyAccount',
    url: routesMap.MyAccount.url,
  },
  {
    index: 1,
    title: 'Mes informations',
    slug: 'MyInfos',
    url: routesMap.MyInfos.url,
  },
  {
    index: 3,
    title: 'Mes adresses & paiements',
    slug: 'MyAddressesAndPayments',
    url: routesMap.MyAddressesAndPayments.url,
  },
  {
    index: 4,
    title: 'Mes commandes',
    slug: 'MyOrders',
    url: routesMap.MyOrders.url,
  },
];

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      isDropDownListOpened: false,
    };
    this.setIsDropDownListOpened = this.setIsDropDownListOpened.bind(this);
  }

  setIsDropDownListOpened(isDropDownListOpened) {
    this.setState({ isDropDownListOpened });
  }

  renderMobile() {
    const { isDropDownListOpened } = this.state;
    const { selectedAccountCategory } = this.props;
    const categoryTitle = menuItems.reduce((acc, item) => {
      if (acc === '' && item.slug === selectedAccountCategory) return item.title;
      return acc;
    }, '');
    return (
      <Container>
        <MobileTitleContainer onClick={() => this.setIsDropDownListOpened(!isDropDownListOpened)}>
          <EmptyDiv />
          <SelectedItem>{categoryTitle}</SelectedItem>
          <ImgMobile src={arrowDown} alt="Menu mon compte" />
        </MobileTitleContainer>
        <OutInSideClick handleClickOutside={() => this.setIsDropDownListOpened(false)}>
          <DropDownList
            dropDownList={menuItems}
            isDisplayed={isDropDownListOpened}
            close={() => this.setIsDropDownListOpened(false)}
          />
        </OutInSideClick>
      </Container>
    );
  }

  renderDesktop() {
    const { selectedAccountCategory, customerContext: { setSelectedOrder } } = this.props;
    return (
      <Container>
        <Title>Mon compte</Title>
        <Line />
        {menuItems.map(({
          index, title, slug, url,
        }) => (
          <MenuItem
            key={index}
            bold={slug === selectedAccountCategory}
          >
            <Link to={url} onClick={() => slug === 'MyOrders' && setSelectedOrder(undefined)}>
              {slug === selectedAccountCategory && <ImgDesktop src={triangle} alt={title} />}
              {title}
            </Link>
          </MenuItem>
        ))}
      </Container>
    );
  }

  render() {
    const { context: { isMobile } } = this.props;
    if (!isMobile) return this.renderDesktop();
    return this.renderMobile();
  }
}

Menu.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  selectedAccountCategory: PropTypes.string.isRequired,
  customerContext: PropTypes.shape({
    setSelectedOrder: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(withAppContext(Menu));
